import { authApi } from "./api";

const BackgroundGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: "api/admin/background",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const BackgroundInfoGetAPI = async (id) => {
  try {
    const response = await authApi({
      method: "get",
      url: `api/admin/background/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const BackgroundPostAPI = async (data) => {
  const formData = new FormData();

  formData.append("backgroundName", data.backgroundName);
  formData.append("backgroundType", data.backgroundType);
  formData.append("sequence", data.sequence);
  formData.append("file", data.file);

  try {
    const response = await authApi({
      method: "post",
      url: `api/admin/background`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const BackgroundPutAPI = async (id, data) => {
  const formData = new FormData();
  formData.append("backgroundName", data.backgroundName);
  formData.append("backgroundType", data.backgroundType);
  formData.append("sequence", data.sequence);
  if (data.file !== undefined) {
    formData.append("file", data.file)
  } else {
    let emptyBlob = new Blob([], { type: "application/octet-stream" });
    formData.append('file', emptyBlob, 'filename.txt');
  }

  try {
    const response = await authApi({
      method: "put",
      url: `api/admin/background/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const BackgroundDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `api/admin/background/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const BackgroundSeqListGetAPI = async (type) => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/background/sequence?backgroundType=${type}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const BackgroundSeqGetAPI = async (id, type) => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/background/sequence/${id}?backgroundType=${type}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  BackgroundGetAPI,
  BackgroundInfoGetAPI,
  BackgroundPostAPI,
  BackgroundPutAPI,
  BackgroundDeleteAPI,
  BackgroundSeqListGetAPI,
  BackgroundSeqGetAPI,
};
