import { authApi } from "./api";

const FooterListGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: "api/admin/address/total",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const FooterGetAPI = async (id) => {
  try {
    const response = await authApi({
      method: "get",
      url: `api/admin/address/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const FooterPostAPI = async (data) => {
  try {
    const response = await authApi({
      method: "post",
      url: `api/admin/address`,
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const FooterPutAPI = async (id, data) => {
  try {
    const response = await authApi({
      method: "put",
      url: `api/admin/address/${id}`,
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const FooterDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `api/admin/address/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  FooterListGetAPI,
  FooterGetAPI,
  FooterPostAPI,
  FooterPutAPI,
  FooterDeleteAPI,
};
