import { styled } from "styled-components";
import { useRecoilValue } from 'recoil';
import AuthState from '../../store/AuthStore';

const TopBar = () => {
  const authState = useRecoilValue(AuthState)

  return (
    <TopBarStyle>
      {authState != "" ? <h4>관리자님</h4> : <h4>Login</h4>}
    </TopBarStyle>
  );
};

export default TopBar;

const TopBarStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  width: 100vw;
  height: 100px;

  z-index: 1000;

  background-color: #000000;

  h4 {
    margin-right: 32px;
  }
`;
