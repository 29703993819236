import { useEffect, useRef, useState } from "react";
import {
  PrivacyPolicyDeleteAPI,
  PrivacyPolicyGetAPI,
  PrivacyPolicyListGetAPI,
  PrivacyPolicyPostAPI,
  PrivacyPolicyPutAPI,
} from "../../api/ConnectApi";
import { DeleteOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import Modal from "../Modal/Modal";

const PrivacyPolicyTextbox = ({ policyIndex, onParentRefetch, onParentCompleteMoadl }) => {
  const [policy, setPolicy] = useState({
    content: "",
    status: true,
    title: "",
  });
  const [refecth, setRefetch] = useState(true);
  const [editState, setEditState] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "auto";
      const scrollH = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollH}px`;
    }
  }, [policy.content, editState]);

  useEffect(() => {
    if (refecth)
      PrivacyPolicyGetAPI(policyIndex)
        .then((res) => setPolicy(res.data.data))
        .catch((error) => console.log(error))
        .finally(() => setRefetch(false));
  }, [refecth]);

  // 모달 상태
  const [cancelModal, setCancelModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const editHandler = () => {
    PrivacyPolicyPutAPI(policyIndex, policy)
      .then(() => {
        setCompleteModal(true);
      })
      .catch(() => {
        setErrorModal(true);
      })
      .finally(() => {
        setRefetch(true);
      });
  };

  const deleteHandler = () => {
    PrivacyPolicyDeleteAPI(policyIndex)
      .then(() => {
        onParentCompleteMoadl(true);
      })
      .catch(() => {
        setErrorModal(true);
      })
      .finally(() => {
        onParentRefetch();
      });
  };

  return (
    <>
      <div className='policy-box-header'>
        {editState ? (
          <div className='buttonwrapper'>
            <img
              src='/assets/check.svg'
              alt=''
              onClick={() => setEditModal(true)}
            />
            <img
              src='/assets/close.svg'
              alt=''
              onClick={() => setCancelModal(true)}
            />
          </div>
        ) : (
          <>
            <img
              className='edit-img'
              src='/assets/Edit.svg'
              alt=''
              onClick={() => setEditState(true)}
            />
            <DeleteOutlined onClick={() => setDeleteModal(true)} />
          </>
        )}
      </div>
      {editState ? (
        <textarea
          className='edit-textarea'
          value={policy.content}
          onChange={(e) =>
            setPolicy((prev) => {
              return { ...prev, content: e.target.value };
            })
          }
          ref={textareaRef}
        ></textarea>
      ) : (
        <div className='policy-box-div'>{policy.content}</div>
      )}
      <Modal
        isYesNo={true}
        onClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        onYesClick={() => deleteHandler()}
      >
        삭제 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setEditModal(false)}
        isOpen={editModal}
        onYesClick={editHandler}
      >
        수정 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setCancelModal(false)}
        isOpen={cancelModal}
        onYesClick={() => {
          setRefetch(true);
          setEditState(false);
        }}
      >
        취소 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </>
  );
};

const PrivacyPolicy = () => {
  const [addState, setAddState] = useState(false);
  const [refecth, setRefetch] = useState(true);
  const [policyList, setPolicyList] = useState([]);
  const [newPolicy, setNewPolicy] = useState({
    content: "",
    status: true,
    title: "",
  });

  useEffect(() => {
    if (refecth)
      PrivacyPolicyListGetAPI()
        .then((res) => setPolicyList(res.data.dataList))
        .catch((error) => console.log(error))
        .finally(() => {
          setNewPolicy({
            content: "",
            status: false,
            title: "",
          });
          setRefetch(false);
        });
  }, [refecth]);

  // 모달 상태
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const addHandler = () => {
    if (newPolicy.title !== "" && newPolicy.content !== "")
      PrivacyPolicyPostAPI(newPolicy)
        .then(() => {
          setCompleteModal(true);
          setAddState(false);
        })
        .catch(() => {
          setErrorModal(true);
        })
        .finally(() => {
          setRefetch(true);
        });
  };

  const Policies = policyList.map((policy, idx) => {
    return (
      <PrivacyPolicyTextbox
        policyIndex={policy.id}
        key={policy.title + policy.id}
        onParentRefetch={() => setRefetch(true)}
        onParentCompleteMoadl={() => {setCompleteModal(true)}}
      />
    );
  });

  return (
    <>
      <PrivactPolicyWrapper>
        <div className='profile-header'>
          <h4>개인정보처리방침</h4>
          <img src='/assets/Add.svg' alt='' onClick={() => setAddState(true)} />
        </div>
        {addState && (
          <div className='profile-content'>
            <p>제목</p>
            <input
              type='text'
              value={newPolicy.title}
              onChange={(e) =>
                setNewPolicy((prev) => {
                  return { ...prev, title: e.target.value };
                })
              }
            />
            <p>내용</p>
            <textarea
              value={newPolicy.content}
              onChange={(e) =>
                setNewPolicy((prev) => {
                  return { ...prev, content: e.target.value };
                })
              }
            ></textarea>
            <div className='buttonwrapper'>
              <img
                src='/assets/check.svg'
                alt=''
                onClick={() => setAcceptModal(true)}
              />
              <img
                src='/assets/close.svg'
                alt=''
                onClick={() => setCancelModal(true)}
              />
            </div>
          </div>
        )}

        <div className='profile-content'>{Policies}</div>
      </PrivactPolicyWrapper>
      <Modal
        isYesNo={true}
        onClose={() => setCancelModal(false)}
        isOpen={cancelModal}
        onYesClick={() => {
          setNewPolicy({
            content: "",
            status: true,
            title: "",
          });
          setAddState(false);
        }}
      >
        취소 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setAcceptModal(false)}
        isOpen={acceptModal}
        onYesClick={addHandler}
      >
        추가 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </>
  );
};

export default PrivacyPolicy;

const PrivactPolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 50px;

  .profile-header {
    display: flex;
    flex-direction: row;

    align-items: center;

    h4 {
      margin-right: 10px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .profile-content {
    width: 748px;
    margin-left: 50px;

    textarea {
      width: 745px;
      height: 300px;
      border: 1px solid #ffffff;
      color: #ffffff;
      font-size: 16px;
      background-color: transparent;
      resize: none;
    }
    .edit-textarea {
      width: 100%;
      height: 500px;

      margin-top: 20px;
    }
  }
  .buttonwrapper {
    display: flex;
    width: 100%;
    justify-content: end;

    img {
      width: 30px;
      height: 30px;

      margin-left: 10px;
    }
  }
  .policy-box-header {
    display: flex;
    justify-content: end;
    align-items: center;

    margin-top: 20px;

    height: 30px;

    img {
      width: 30px;
      height: 30px;

      margin-left: 10px;
    }

    svg {
      width: 25px;
      height: 25px;

      margin-left: 10px;
    }

    .edit-img {
      width: 25px;
      height: 25px;
    }
  }

  .policy-box-div {
    width: 100%;
    border: 1px solid #ffffff;

    margin: 20px 0;
  }

  input {
    width: 100%;
    height: 30px;
    border: 1px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
    font-size: 16px;
  }
`;
