import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { ServicePostAPI, ServiceTagPostAPI } from "../../api/AboutApi";

const ServiceAdd = ({ service, serviceTypeList, onRefetch, onAddState }) => {
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const [postContentType, setPostContentType] = useState("");

  const [postData, setPostData] = useState({
    content: "",
    sequence:
      service[postContentType] && service[postContentType].length > 0
        ? service[postContentType][service[postContentType].length - 1]
            .sequence + 1
        : 1,
    serviceType: "",
    serviceTypeId: 0,
  });
  useEffect(() => {
    setPostData((prev) => {
      return {
        ...prev,
        serviceTypeId: serviceTypeList[0].id,
      };
    });
    setPostContentType(serviceTypeList[0].serviceTypeName);
  }, [service]);

  useEffect(() => {
    setPostData((prev) => {
      return {
        ...prev,
        sequence:
          service[postContentType] && service[postContentType].length > 0
            ? service[postContentType][service[postContentType].length - 1]
                .sequence + 1
            : 1,
      };
    });
  }, [postContentType]);

  const optionHandler = (e) => {
    const [type, id] = e.target.value.split("|");
    console.log(type);
    console.log(service[type] && service[type].length);
    setPostContentType(type);
    setPostData((prev) => {
      return {
        ...prev,
        serviceType: type,
        serviceTypeId: id,
        sequence:
          service[type] && service[type].length > 0
            ? service[type][service[type].length - 1].sequence + 1
            : 1,
      };
    });
  };

  const inputHandler = (e) => {
    setPostData((prev) => {
      return { ...prev, content: e.target.value };
    });
  };

  const addHandler = async () => {
    const data = {
      ...postData,
      serviceType: postContentType,
    };

    if (postContentType === "ServiceAdd") {
      ServiceTagPostAPI(data)
        .then((res) => {
          setAcceptModal(false);
          onAddState(false);
        })
        .catch((err) => {
          setAcceptModal(false);
          setErrorModal(true);
          onAddState(false);
          console.log(err);
        })
        .finally(() => onRefetch(true));
      return;
    }

    ServicePostAPI(data)
      .then((res) => {
        setAcceptModal(false);
        onAddState(false);
      })
      .catch((err) => {
        setAcceptModal(false);
        setErrorModal(true);
        onAddState(false);
        console.log(err);
      })
      .finally(() => onRefetch(true));
  };

  const TypeSelecter =
    serviceTypeList &&
    serviceTypeList.map((type, idx) => {
      return (
        <option
          key={type.serviceTypeName + type.id}
          value={`${type.serviceTypeName}|${type.id}`}
        >
          {String.fromCharCode(65 + idx) + ` (${type.serviceTypeName})`}
        </option>
      );
    });

  return (
    <div className='service-add'>
      <div>
        <h4>Service</h4>
        <select name='' id='' onChange={optionHandler}>
          {TypeSelecter}
          <option value='ServiceAdd'>서비스 항목 추가</option>
        </select>
      </div>
      <div>
        <h4>Text</h4>
        <input type='text' value={postData.content} onChange={inputHandler} />
        <img
          src='/assets/check.svg'
          alt=''
          onClick={() => setAcceptModal(true)}
        />
        <img
          src='/assets/close.svg'
          alt=''
          onClick={() => setCancelModal(true)}
        />
      </div>
      <Modal
        isYesNo={true}
        onClose={() => setCancelModal(false)}
        isOpen={cancelModal}
        onYesClick={() => onAddState(false)}
      >
        취소 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setAcceptModal(false)}
        isOpen={acceptModal}
        onYesClick={addHandler}
      >
        추가 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </div>
  );
};

export default ServiceAdd;
