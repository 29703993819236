import { useEffect, useState } from "react";
import { ProjectProcessGetAPI, ProjectTypeDeleteAPI, ProjectTypeGetAPI, ProjectTypePutAPI } from "../../api/AboutApi";
import { styled } from "styled-components";
import ProjectAdd from "./ProjectAdd";
import ProjectUpdate from "./ProjectUpdate";
import { DeleteOutlined } from '@ant-design/icons';
import Modal from '../Modal/Modal';

const Project = () => {
  const [refetchState, setRefetchState] = useState(true);

  const [processTypeList, setProcessTypeList] = useState([]);

  const [processData, setProcessData] = useState(null);

  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);

  const [selectCategory, setSelectCategory] = useState(null);
  const [categoryName, setCategoryName] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const selectHandler = (e) => {
    setSelectCategory(e.target.value);
  };

  const deleteHandler = () => {
    const item = processTypeList.find(
      (data) => data.projectProcessTypeName === selectCategory
    );
    ProjectTypeDeleteAPI(item.id)
      .then((res) => {
        setSelectCategory(null);
        setCompleteModal(true);
      })
      .catch((error) => {
        setErrorModal(true);
      })
      .finally(() => {
        setDeleteModal(false);
        setRefetchState(true);
        setEditState(false);
      });
  };

  const PutHandler = () => {
    const id = processTypeList.find(
      (item) => item.projectProcessTypeName === selectCategory
    ).id;

    ProjectTypePutAPI(id, categoryName)
      .then((res) => {
        setSelectCategory(null);
        setCompleteModal(true);
      })
      .catch((error) => {
        setErrorModal(true);
      })
      .finally(() => {
        setEditModal(false);
        setRefetchState(true);
        setEditState(false);
      });
  };

  useEffect(() => {
    setCategoryName(selectCategory);
  }, [selectCategory, editState]);

  useEffect(() => {
    if (refetchState == true) {
      const fetchProcessType = async () => {
        try {
          const response = await ProjectTypeGetAPI();
          return response;
        } catch (error) {
          console.log(error);
        }
      };
      const fetchProcess = async () => {
        try {
          const response = await ProjectProcessGetAPI();
          return response;
        } catch (error) {
          console.log(error);
        }
      };
      fetchProcessType()
        .then((response) => {
          const responseData = response.data.dataList;
          setProcessTypeList(responseData);
          if (selectCategory === null) {
            setSelectCategory(responseData[0].projectProcessTypeName);
          }
          const groupedAndSortedInit = responseData.reduce((acc, item) => {
            acc[item.projectProcessTypeName] = [];
            return acc;
          }, {});
          return fetchProcess().then((processRes) => ({
            processData: processRes.data,
            initialGroup: groupedAndSortedInit,
          }));
        })
        .then(({ processData, initialGroup }) => {
          const groupedAndSorted = { ...initialGroup }; // 위에서 초기화한 객체를 복사
          console.log(processData);
          processData.dataList.forEach((item) => {
            if (groupedAndSorted.hasOwnProperty(item.projectProcessType)) {
              groupedAndSorted[item.projectProcessType].push(item);
            }
          });

          Object.keys(groupedAndSorted).forEach((projectProcessType) => {
            groupedAndSorted[projectProcessType].sort(
              (a, b) => a.sequence - b.sequence
            );
          });
          console.log(groupedAndSorted);
          setProcessData(groupedAndSorted);
        });
    }

    setRefetchState(false);
  }, [refetchState]);

  // console.log(processData);

  const Lists =
    processData &&
    selectCategory &&
    processData[selectCategory] !== undefined &&
    processData[selectCategory].map((item, idx) => {
      return (
        <li key={selectCategory + item.content + idx}>
          <>{item.content ? item.content : `\u2001`}</>
        </li>
      );
    });

  const TypeSelecter =
    processTypeList &&
    processTypeList.map((type, idx) => {
      return (
        <option
          key={type.projectProcessTypeName + type.id}
          value={`${type.projectProcessTypeName}`}
        >
          {type.projectProcessTypeName}
        </option>
      );
    });

  return (
    <ProjectWrapper>
      <div className='service-header'>
        <h3>ProjectProcess</h3>
        <img
          src='/assets/Add.svg'
          alt=''
          onClick={() => {
            setAddState(true);
            setEditState(false);
          }}
        />
        <img
          src='/assets/Setting.svg'
          alt=''
          onClick={() => {
            setEditState(true);
            setAddState(false);
          }}
        />
      </div>
      {addState && (
        <ProjectAdd
          processlist={processData}
          processTypeList={processTypeList}
          onRefetch={() => setRefetchState(true)}
          onAddState={setAddState}
        />
      )}

      <div className='service-content'>
        <div className='service-sub-header'>
          <select name='' id='' onChange={selectHandler}>
            {TypeSelecter}
          </select>
          {/* <h4>{selectCategory}</h4> */}
          <CategoryInputDiv>
            <input
              type='text'
              value={categoryName}
              disabled={!editState}
              onChange={(e) => {
                setCategoryName(e.target.value);
              }}
            />
            {editState && (
              <>
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <DeleteOutlined
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                />
              </>
            )}
          </CategoryInputDiv>
        </div>
        <div className='service-sub-content'>
          {editState ? (
              <ProjectUpdate
                processTypeList={processTypeList}
                selectCategory={selectCategory}
                processlist={processData}
                onRefetch={setRefetchState}
                onEditState={setEditState}
              />
          ) : (
            <ul>{Lists}</ul>
          )}
        </div>
      </div>
      <Modal
        isYesNo={true}
        isOpen={editModal}
        onClose={() => setEditModal(false)}
        onYesClick={() => PutHandler()}
      >
        수정 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
      <Modal
        isYesNo={true}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onYesClick={() => {
          deleteHandler();
        }}
      >
        삭제 하시겠습니까?
      </Modal>
    </ProjectWrapper>
  );
};

export default Project;

const ProjectWrapper = styled.div`
  .service-header {
    display: flex;

    align-items: center;

    img {
      width: 25px;
      height: 25px;

      margin-left: 20px;
    }
  }

  .service-content {
    margin-left: 50px;
    margin-top: 30px;
  }

  .service-add {
    display: flex;
    flex-direction: column;

    margin-top: 30px;

    > div {
      display: flex;
      flex-direction: row;

      align-items: center;

      margin-bottom: 20px;
    }

    select {
      width: 200px;
      height: 40px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      background-color: #707070;
      border: 1px solid #ffffff;
    }

    h4 {
      width: 100px;
    }

    input {
      width: 300px;
      height: 40px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      background-color: #707070;
      border: 1px solid #ffffff;
    }

    img {
      margin-left: 10px;
    }
  }

  .service-sub-header {
    display: flex;
    align-items: center;

    height: 40px;
    width: 70%;

    background-color: #707070;

    h4 {
      margin-left: 20px;
    }

    select {
      width: 100px;
      height: 40px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      background-color: #707070;
    }
  }
  .service-sub-content {
    width: 70%;
    ul {
      margin-left: 100px;

      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        font-weight: bold;
        border-bottom: #ffffff 1px solid;

        padding-left: 20%;
        padding-top: 10px;
        padding-bottom: 10px;

        input {
          width: 70%;
          height: 21px;
          color: #ffffff;
          font-size: 16px;
          background-color: #707070;
          border: 1px solid #ffffff;
        }

        svg {
          height: 20px;
          width: 20px;
        }

        .anticon-menu {
          position: absolute;
          left: 0%;
        }
      }
    }
  }

  .edit-button {
    width: 100%;
    text-align: end;

    img {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
`;

const CategoryInputDiv = styled.div`
  display: flex;
  align-items: center;
  input {
    color: white;
    background-color: transparent;
    border: 1px solid white;

    font-size: 18px;
    font-weight: 700;

    &:disabled {
      border: none;
    }
  }
  img {
    margin-left: 20px;
    height: 20px;
    width: 20px;
  }
  svg {
    margin-left: 5px;
  }
`;
