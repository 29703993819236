import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #000000;
    color: #ffffff;
    font-family: Pretanard, sans-serif;
    line-height: 1.6;
  }

  a {
    text-decoration: none;
    color: #ffffff;
  }

  h1 {
    font-size: 32px;
    font-weight: 900;
  }
  h2 {
    font-size: 28px;
    font-weight: 800;
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    font-weight: 400;
  }

  img {
    &:hover {
      cursor: pointer;
    }
  }
  svg {
      &:hover {
        cursor: pointer;
      }
  }
  label {
      &:hover {
        cursor: pointer;
      }
  }
`;

export default GlobalStyle;
