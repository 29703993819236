import { authApi } from "./api";

const CompanyProfileListGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: "/api/admin/company-profile",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const CompanyProfilePostAPI = async (data) => {
  const formdata = new FormData();

  formdata.append("file", data.file);
  formdata.append("title", data.title);
  formdata.append("status", data.status);

  try {
    const response = await authApi({
      method: "post",
      url: "/api/admin/company-profile",
      data: formdata,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const CompanyProfileDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `/api/admin/company-profile/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PrivacyPolicyListGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: "/api/admin/privacy-policy/total",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PrivacyPolicyGetAPI = async (id) => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/privacy-policy/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PrivacyPolicyPostAPI = async (data) => {
  try {
    const response = await authApi({
      method: "post",
      url: `/api/admin/privacy-policy`,
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PrivacyPolicyPutAPI = async (id, data) => {
  try {
    const response = await authApi({
      method: "put",
      url: `/api/admin/privacy-policy/${id}`,
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PrivacyPolicyDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `/api/admin/privacy-policy/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjcetBudgetGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/project-request/budget`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
const ProjcetPeriodGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/project-request/period`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
const ProjcetStatusGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/project-request/status`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjcetTypeGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/project-request/type`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjcetSearchGetAPI = async (query) => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/project-request/?keyword=${query.keyword}&projectBudget=${query.projectBudget}&projectPeriod=${query.projectPeriod}&projectPreparingStatus=${query.projectPreparingStatus}&projectRequestType=${query.projectRequestType}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjcetRequestGetAPI = async (id) => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/project-request/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};


const ProjcetRequestDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `/api/admin/project-request/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  CompanyProfileListGetAPI,
  PrivacyPolicyListGetAPI,
  CompanyProfilePostAPI,
  CompanyProfileDeleteAPI,
  PrivacyPolicyGetAPI,
  PrivacyPolicyPostAPI,
  PrivacyPolicyPutAPI,
  PrivacyPolicyDeleteAPI,
  ProjcetBudgetGetAPI,
  ProjcetPeriodGetAPI,
  ProjcetStatusGetAPI,
  ProjcetTypeGetAPI,
  ProjcetSearchGetAPI,
  ProjcetRequestGetAPI,
  ProjcetRequestDeleteAPI,
};
