import { styled } from 'styled-components';

const Content = ({ children }) => {
  return <ContentStyle>{children}</ContentStyle>;
};

export default Content;

const ContentStyle = styled.div`

  width: calc(100vw - 270px);
  min-height: calc(100vh - 120px);

  margin-top: 100px;
  margin-left: 250px;
`;