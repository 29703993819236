import { Route, Routes } from "react-router-dom";
import { styled } from "styled-components";
import CompanyInfo from "../components/Contect/CompanyInfo";
import CustomerProject from "../components/Contect/CustomerProject";
import CustomerDetail from "../components/Contect/CustomerDetail";
import { useState } from "react";
import Modal from "../components/Modal/Modal";

const ConnectPage = () => {
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  return (
    <ConnectPageWrapper>
      <h1>Contact</h1>
      <hr className='mainhr' />
      <Routes>
        <Route path='/company-info' element={<CompanyInfo />} />
        <Route path='/project' element={<CustomerProject />} />
        <Route
          path='/project/*'
          element={
            <CustomerDetail
              setCompleteModal={setCompleteModal}
              setErrorModal={setErrorModal}
            />
          }
        />
      </Routes>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </ConnectPageWrapper>
  );
};

export default ConnectPage;

const ConnectPageWrapper = styled.div`
  margin-left: 168px;
  .mainhr {
    width: 30px;

    margin-left: 0;
  }
  .bgcontents {
    margin-left: 50px;
  }
`;
