import { Link, NavLink, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { useRecoilState } from "recoil";
import AuthState from "../../store/AuthStore";
import { useEffect, useRef, useState } from "react";
import Modal from "../Modal/Modal";

const SideNav = () => {
  const [authState, setAuthState] = useRecoilState(AuthState);

  const [modalState, setModalState] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const location = useLocation();
  const aboutSubLinkRef = useRef(null);
  const connectSubLinkRef = useRef(null);
  const [aboutSubLinkHeight, setaboutSubLinkHeight] = useState(0);
  const [connectSubLinkHeight, setconnectSubLinkHeight] = useState(0);

  useEffect(() => {
    let aboutHeight = 0;
    let connectHeight = 0;
    if (aboutSubLinkRef.current) {
      Array.from(aboutSubLinkRef.current.children).forEach((child) => {
        const style = window.getComputedStyle(child);
        const marginTop = parseFloat(style.marginTop) || 0;
        const marginBottom = parseFloat(style.marginBottom) || 0;

        aboutHeight += child.offsetHeight + marginTop + marginBottom;
      });
    }
    if (connectSubLinkRef.current) {
      Array.from(connectSubLinkRef.current.children).forEach((child) => {
        const style = window.getComputedStyle(child);
        const marginTop = parseFloat(style.marginTop) || 0;
        const marginBottom = parseFloat(style.marginBottom) || 0;

        connectHeight += child.offsetHeight + marginTop + marginBottom;
      });
    }
    setaboutSubLinkHeight(aboutHeight);
    setconnectSubLinkHeight(connectHeight);
  }, []);

  const logoutHandler = () => {
    setCompleteModal(true);
    setAuthState("");
  };

  return (
    <SideNavStyle>
      <div className='mainNav'>
        <div className='mainLink'>
          <NavLink to={"/"}>Main</NavLink>
        </div>
        <div className='mainLink'>
          <NavLink to={"/about"} className={"haveSublink"}>
            About
          </NavLink>
          <SubLinkStyle
            className={`sublink ${
              location.pathname.split("/")[1] === "about" ? "active" : ""
            }`}
            ref={aboutSubLinkRef}
            $sublinkheight={aboutSubLinkHeight}
          >
            <NavLink to={"/about/service"}>Service</NavLink>
            <NavLink to={"/about/process"}>Project process</NavLink>
            <NavLink to={"/about/partners"}>Partners</NavLink>
          </SubLinkStyle>
        </div>
        <div className='mainLink'>
          <NavLink to={"/work"}>Work</NavLink>
        </div>
        <div className='mainLink'>
          <NavLink to={"/contact"}>Contact</NavLink>
          <SubLinkStyle
            className={`sublink ${
              location.pathname.split("/")[1] === "contact" ? "active" : ""
            }`}
            ref={connectSubLinkRef}
            $sublinkheight={connectSubLinkHeight}
          >
            <NavLink to={"/contact/company-info"}>페이지 관리</NavLink>
            <NavLink to={"/contact/project"}>프로젝트 의뢰 관리</NavLink>
          </SubLinkStyle>
        </div>
        <div className='mainLink'>
          <NavLink to={"/footer"}>Footer</NavLink>
        </div>
      </div>
      {authState != "" && (
        <>
          <div className='LogoutLink'>
            <a
              onClick={(e) => {
                e.preventDefault();
                setModalState(true);
              }}
              href='#'
            >
              Logout
            </a>
          </div>
        </>
      )}
      <Modal
        isYesNo={true}
        isOpen={modalState}
        onClose={() => setModalState(false)}
        onYesClick={logoutHandler}
      >
        로그아웃 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </SideNavStyle>
  );
};

export default SideNav;

const SideNavStyle = styled.div`
  position: fixed;
  top: 100px;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 250px;
  height: calc(100vh - 100px);

  overflow: auto;

  .mainNav {
    display: flex;
    flex-direction: column;

    padding-left: 54px;
    padding-top: 104px;
  }

  .mainLink {
    margin-bottom: 35px;
  }

  a {
    position: relative;

    width: fit-content;

    font-size: 32px;
    font-weight: bold;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 50%;
      width: 0%;
      border: none;

      transition: 0.5s all;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0%;
      border: none;

      transition: 0.5s all;
    }

    &.active {
      &::before {
        width: 50%;
        border: 1px solid #ffffff;
      }
      &::after {
        width: 50%;
        border: 1px solid #ffffff;
      }
    }
  }
  .LogoutLink {
    padding-left: 54px;
    margin-bottom: 52px;

    a {
      font-size: 24px;
    }
  }
`;

const SubLinkStyle = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 10px;
  height: 0px;

  overflow: hidden;

  transition: 0.5s all;

  &.active {
    height: ${(props) => `${props.$sublinkheight}px`};
    margin-top: 8px;
  }

  a {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 8px;
  }
`;
