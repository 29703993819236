import { styled } from "styled-components";
import WorkAreaList from "../components/Work/WorkAreaList";
import { Route, Routes } from "react-router-dom";
import WorkDetail from '../components/Work/WorkDetail';

const WorkPage = () => {
  return (
    <>
      <WorkPageWrapper>
        <h1>Work</h1>
        <hr className='mainhr' />
        <Routes>
          <Route path='/' element={<WorkAreaList />} />
          <Route path='/detail/*' element={<WorkDetail />} />
        </Routes>
      </WorkPageWrapper>
    </>
  );
};

export default WorkPage;

const WorkPageWrapper = styled.div`
  margin-left: 168px;
  .mainhr {
    width: 30px;

    margin-left: 0;
  }
  .bgcontents {
    margin-left: 50px;
  }
  .main-header {
    display: flex;
    align-items: center;
  }
`;
