import { styled } from "styled-components";
import CompanyProfile from "./CompanyProfile";
import PrivacyPolicy from './CompanyPolicy';



const CompanyInfo = () => {
  return (
    <ComponyInfoWrapper>
      <CompanyProfile />
      <PrivacyPolicy />
    </ComponyInfoWrapper>
  );
};

export default CompanyInfo;

const ComponyInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
