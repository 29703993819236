import { Route, Routes } from "react-router-dom";
import Content from "./components/common/Content";
import SideNav from "./components/common/SideNav";
import TopBar from "./components/common/TopBar";
import GlobalStyle from "./style/globalstyle";
import AboutPage from "./page/About";
import ConnectPage from "./page/Contect";
import FooterPage from "./page/Footer";
import MainPage from "./page/Main";
import WorkPage from "./page/Work";
import LoginPage from "./page/Login";
import PrivateRoute from "./components/common/PrivateRoute";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Loading from "./components/common/Loading";

function App() {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <GlobalStyle />
        <TopBar />
        <SideNav />
        <Content>
          <Routes>
            <Route
              path='/'
              element={
                <PrivateRoute>
                  <MainPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/about/*'
              element={
                <PrivateRoute>
                  <AboutPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/contact/*'
              element={
                <PrivateRoute>
                  <ConnectPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/footer'
              element={
                <PrivateRoute>
                  <FooterPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/work/*'
              element={
                <PrivateRoute>
                  <WorkPage />
                </PrivateRoute>
              }
            />
            <Route path='/login' element={<LoginPage />} />
          </Routes>
        </Content>
        <Loading />
      </DndProvider>
    </>
  );
}

export default App;
