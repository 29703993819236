import { authApi } from "./api";

const ProjectAreaGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: "/api/admin/project-area",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjcetAreaPostAPI = async (type) => {
  try {
    const response = await authApi({
      method: "post",
      url: `/api/admin/project-area`,
      data: { gridAreaType: type },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjcetAreaDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `/api/admin/project-area/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjcetAreaDetailGetAPI = async (id) => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/project-area/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const downloadFileFromURL = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

const ProjcetAreaDetailPutAPI = async (data, id, designers, developers) => {
  const { mainFile, workFile } = data.projectInfoDto;
  const { files } = data.projectInfoDto;
  const formData = new FormData();
  if (data.projectInfoDto.mainFile.file) {
    formData.append("mainFile", data.projectInfoDto.mainFile.file);
  } else if (!mainFile.fileUrl) {
    console.log("파일을 비워두면 안됩니다!");
    throw "파일을 비워두면 안됩니다!";
  } else {
    const blob = await downloadFileFromURL(mainFile.fileUrl);
    formData.append("mainFile", blob, mainFile.fileName);
  }
  if (data.projectInfoDto.workFile.file) {
    formData.append("workFile", data.projectInfoDto.workFile.file);
  }  else if (!workFile.fileUrl){
    console.log(workFile)
    console.log("파일을 비워두면 안됩니다!")
    throw "파일을 비워두면 안됩니다!"
  } else {
    const blob = await downloadFileFromURL(workFile.fileUrl);
    formData.append("workFile", blob, workFile.fileName);
  }

  for (const fileObj of files) {
    if (fileObj.file) {
      formData.append("files", fileObj.file);
    } else if (fileObj.fileUrl) {
      const blob = await downloadFileFromURL(fileObj.fileUrl);
      formData.append("files", blob, fileObj.fileName);
    }
  }

  const createQueryForArray = (array, prefix) => {
    let queryString = "";
    array.forEach((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        if (value === null) {
          continue;
        }
        queryString += `&${prefix}%5B${index}%5D.${key}=${encodeURIComponent(
          value
        )}`;
      }
    });
    return queryString;
  };

  const designersQueryString = createQueryForArray(
    designers,
    "designerDtoList"
  );
  const developersQueryString = createQueryForArray(
    developers,
    "developerDtoList"
  );

  try {
    const response = await authApi({
      method: "put",
      url: `api/admin/project-area/${id}?clientName=${data.projectInfoDto.clientName}&endDate=${data.projectInfoDto.endDate}&gridAreaType=${data.gridAreaType}&projectId=${data.projectAreaId}&projectName=${data.projectInfoDto.projectName}&projectType=${data.projectInfoDto.projectType}&
      `+designersQueryString+developersQueryString,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  ProjectAreaGetAPI,
  ProjcetAreaPostAPI,
  ProjcetAreaDeleteAPI,
  ProjcetAreaDetailGetAPI,
  ProjcetAreaDetailPutAPI,
};
