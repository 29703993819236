import { useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  FooterDeleteAPI,
  FooterGetAPI,
  FooterListGetAPI,
  FooterPutAPI,
} from "../../api/FooterApi";
import { DeleteOutlined } from "@ant-design/icons";
import Modal from "../Modal/Modal";

const FooterAddress = ({ address, onRefetch }) => {
  const [editState, setEditState] = useState(false);
  const [addressState, setAddressState] = useState({
    address: "",
    id: 0,
    status: true,
  });

  useEffect(() => {
    FooterGetAPI(address.id)
      .then((res) => {
        setAddressState(res.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const addressChangeHandler = (e) => {
    setAddressState((prev) => {
      return { ...prev, address: e.target.value };
    });
  };

  const statusChangeHandler = () => {
    setAddressState((prev) => {
      return { ...prev, status: !prev.status };
    });
  };

  // 모달 상태
  const [deleteModal, setDeleteModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const editHandler = () => {
    FooterPutAPI(address.id, addressState)
      .then(() => {
        setCompleteModal(true);
        setEditState(false);
      })
      .catch(() => {
        setErrorModal(true);
      })
      .finally(() => {
        onRefetch();
      });
  };

  const deleteHandler = () => {
    FooterDeleteAPI(address.id)
      .then(() => {
        setCompleteModal(true);
        setEditState(false);
      })
      .catch(() => {
        setErrorModal(true);
      })
      .finally(() => {
        onRefetch();
      });
  };

  return (
    <>
      <div className='inputwrapper'>
        {editState ? (
          <input
            type='text'
            value={addressState.address}
            onChange={addressChangeHandler}
          />
        ) : (
          <div>{addressState.address}</div>
        )}
        {editState ? (
          <>
            {addressState.status ? (
              <img
                src='/assets/Display.svg'
                alt=''
                onClick={statusChangeHandler}
              />
            ) : (
              <img
                src='/assets/DisplayNone.svg'
                alt=''
                onClick={statusChangeHandler}
              />
            )}
            <img
              src='/assets/Check.svg'
              alt=''
              onClick={() => setEditModal(true)}
            />
            <img
              src='/assets/Close.svg'
              alt=''
              onClick={() => setCancelModal(true)}
            />
          </>
        ) : (
          <>
            {addressState.status ? (
              <img src='/assets/Display.svg' alt='' className='id-display' />
            ) : (
              <img src='/assets/DisplayNone.svg' alt='' className='id-display' />
            )}
            <img
              src='/assets/Edit.svg'
              alt=''
              onClick={() => setEditState(true)}
            />
            {/* <DeleteOutlined onClick={() => setDeleteModal(true)} /> */}
          </>
        )}
      </div>
      <Modal
        isYesNo={true}
        onClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        onYesClick={() => deleteHandler()}
      >
        삭제 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setCancelModal(false)}
        isOpen={cancelModal}
        onYesClick={() => setEditState(false)}
      >
        취소 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setEditModal(false)}
        isOpen={editModal}
        onYesClick={editHandler}
      >
        수정 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </>
  );
};

export default FooterAddress;
