import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ProjcetAreaDetailGetAPI,
  ProjcetAreaDetailPutAPI,
} from "../../api/WorkApi";
import Modal from "../Modal/Modal";
import { useSetRecoilState } from "recoil";
import { LoadingAtom } from "../../store/LoadingStore";
import { DeleteOutlined, FileOutlined, MenuOutlined } from "@ant-design/icons";
import { useDrag, useDrop } from "react-dnd";
import { styled } from "styled-components";

// 옵션
const designerOptions = ["DESIGN_DIRECTOR", "GUI_DESIGNER", "UX_UI_DESIGNER"];
const developerOptions = [
  "DEVELOPER_DIRECTOR",
  "FRONT_DEVELOPER",
  "BACK_DEVELOPER",
];

// 드래그 요소
const DRAG_TYPE = "ITEM";

const DraggableItem = ({ item, index, moveItem, children, draggable }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: DRAG_TYPE,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAG_TYPE,
    item: { id: item.id, index },
    canDrag: draggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <li ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </li>
  );
};

const WorkDetail = () => {
  const setLoading = useSetRecoilState(LoadingAtom);
  const location = useLocation();
  const [refetch, setRefetch] = useState(true);
  const [workData, setWorkData] = useState({
    gridAreaType: "",
    projectAreaId: 0,
    projectInfoDto: {
      clientName: "",
      endDate: "",
      files: [
        {
          fileName: "",
          fileType: "",
          fileUrl: "",
          id: 0,
          seq: 0,
        },
      ],
      id: 0,
      mainFile: {
        fileName: "",
        fileType: "",
        fileUrl: "",
        id: 0,
      },
      projectName: "",
      projectType: "",
      workFile: {
        fileName: "",
        fileType: "",
        fileUrl: "",
        id: 0,
      },
      projectAttendantDtoList: [
        {
          designerType: null,
          developerType: null,
          id: 0,
          name: "",
          seq: 0,
        },
      ],
    },
  });
  const [developers, setDevelopers] = useState([]);
  const [designers, setDesigners] = useState([]);

  // 모달 상태
  const [deleteModal, setDeleteModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [ableKey, setAbleKey] = useState("");

  function sortAndFilterByType(data) {
    // developerType이 존재하는 객체 리스트 필터링
    const developers = data
      .filter((item) => item.developerType !== null)
      .sort((a, b) => a.seq - b.seq);

    // designerType이 존재하는 객체 리스트 필터링
    const designers = data
      .filter((item) => item.designerType !== null)
      .sort((a, b) => a.seq - b.seq);

    return {
      developers,
      designers,
    };
  }

  function setFirstData(workData, developers, desingers) {
    setWorkData(workData);
    setDevelopers(developers);
    setDesigners(desingers);
  }

  useEffect(() => {
    if (refetch) {
      const workId = location.pathname.split("/")[3];
      ProjcetAreaDetailGetAPI(workId).then((response) => {
        if (response.data.data.projectInfoDto) {
          const { developers, designers } = sortAndFilterByType(
            response.data.data.projectInfoDto.projectAttendantDtoList
          );
          setFirstData(response.data.data, developers, designers);
        } else {
          setWorkData((prev) => {
            return {
              ...prev,
              gridAreaType: response.data.data.gridAreaType,
              projectAreaId: response.data.data.projectAreaId,
            };
          });
        }
      });
    }

    setRefetch(false);
  }, [refetch]);

  // 인풋 관리

  const [inputDisabled, setInputDisabled] = useState({
    projectName: true,
    endDate: true,
    clientName: true,
    designer: true,
    developer: true,
    mainfile: true,
    workfile: true,
    files: true,
  });

  // 최초로 만든 work의 경우

  useMemo(() => {
    if (workData.projectInfoDto.id === 0) {
      let newDisabledState = {
        projectName: false,
        endDate: false,
        clientName: false,
        designer: false,
        developer: false,
        mainfile: false,
        workfile: false,
        files: false,
      };
      setInputDisabled(newDisabledState);
    } else {
      let newDisabledState = {
        projectName: true,
        endDate: true,
        clientName: true,
        designer: true,
        developer: true,
        mainfile: true,
        workfile: true,
        files: true,
      };
      setInputDisabled(newDisabledState);
    }
  }, [workData.projectInfoDto.id, refetch]);

  // 에디트 핸들러

  const editHandler = () => {
    const workId = location.pathname.split("/")[3];
    setLoading(true);
    ProjcetAreaDetailPutAPI(workData, workId, designers, developers)
      .then(() => {
        setCompleteModal(true);
      })
      .catch(() => {
        setErrorModal(true);
      })
      .finally(() => {
        resetHandler();
        setLoading(false);
      });
  };

  // 편집 가능한 항목 핸들러

  const inputAbleHandler = (e) => {
    let newDisabledState = {
      projectName: true,
      endDate: true,
      clientName: true,
      designer: true,
      developer: true,
      mainfile: true,
      workfile: true,
      files: true,
    };

    const ableKey = e.target.id.split("-")[0];

    const anyFalse = Object.values(inputDisabled).some((value) => !value);
    if (anyFalse) {
      setAbleKey(ableKey);
      setCancelModal(true);
      return;
    }

    // 원하는 키의 값만 false로 설정
    if (newDisabledState.hasOwnProperty(ableKey)) {
      newDisabledState[ableKey] = false;
    }

    setInputDisabled(newDisabledState);
  };

  // 인풋 핸들러

  const inputHandler = (e) => {
    const inputKey = e.target.id.split("-")[0];
    setWorkData((prev) => {
      return {
        ...prev,
        projectInfoDto: { ...prev.projectInfoDto, [inputKey]: e.target.value },
      };
    });
  };

  // 디버깅용

  useEffect(() => {
    console.log(workData.projectInfoDto);
  }, [workData]);

  // 리셋 핸들러, 리페치 + 다른 항목 초기화
  const resetHandler = (ableKey) => {
    let newDisabledState = {
      projectName: true,
      endDate: true,
      clientName: true,
      designer: true,
      developer: true,
      mainfile: true,
      workfile: true,
      files: true,
    };

    if (newDisabledState.hasOwnProperty(ableKey)) {
      newDisabledState[ableKey] = false;
    }

    setAbleKey("");
    // setNewDesinger([]);
    // setNewDeveloper([]);
    setInputDisabled(newDisabledState);
    setRefetch(true);
  };

  // 디자이너, 디벨로퍼 옵션 핸들러
  const handleSelectChange = (event, attend, idx) => {
    switch (attend) {
      case "developer":
        const updateDeveloperList = [...developers];
        updateDeveloperList[idx].developerType = event.target.value;
        setDevelopers(updateDeveloperList);
        break;
      case "designer":
        const updateDesignerList = [...designers];
        updateDesignerList[idx].designerType = event.target.value;
        setDesigners(updateDesignerList);
        break;
      default:
        console.log("유효하지 않은 타입입니다.");
    }
  };

  const handleRemove = (attend, idx) => {
    switch (attend) {
      case "developer":
        const newDevelopers = [...developers];
        newDevelopers.splice(idx, 1);
        setDevelopers(newDevelopers);
        break;
      case "designer":
        const newDesigner = [...designers];
        newDesigner.splice(idx, 1);
        setDesigners(newDesigner);
        break;
      default:
        console.log("유효하지 않은 타입입니다.");
    }
  };

  const attendInputHandler = (event, attend, idx) => {
    switch (attend) {
      case "developer":
        const updateDeveloperList = [...developers];
        updateDeveloperList[idx].name = event.target.value;
        setDevelopers(updateDeveloperList);
        break;
      case "designer":
        const updateDesignerList = [...designers];
        updateDesignerList[idx].name = event.target.value;
        setDesigners(updateDesignerList);
        break;
      default:
        console.log("유효하지 않은 타입입니다.");
    }
  };

  // 디자이너, 디벨로퍼 이동

  const attendMove = (fromIndex, toIndex, attend) => {
    switch (attend) {
      case "Developer":
        const updateddevelopers = [...developers];
        const [moveddevelopers] = updateddevelopers.splice(fromIndex, 1);
        updateddevelopers.splice(toIndex, 0, moveddevelopers);
        setDevelopers(updateddevelopers);
        break;
      case "Designer":
        const updateddesigners = [...designers];
        const [moveddesigners] = updateddesigners.splice(fromIndex, 1);
        updateddesigners.splice(toIndex, 0, moveddesigners);
        setDesigners(updateddesigners);
        break;
      default:
        console.log("잘못된 유형입니다.");
    }
  };

  // 새 디자이너 추가

  const addDesingerHandler = () => {
    setDesigners((prev) => [
      ...prev,
      {
        designerType: "DESIGN_DIRECTOR",
        developerType: null,
        name: "",
      },
    ]);
  };

  // 새 디벨로퍼 추가

  const addDeveloperHandler = () => {
    setDevelopers((prev) => [
      ...prev,
      {
        designerType: null,
        developerType: "DEVELOPER_DIRECTOR",
        name: "",
      },
    ]);
  };

  // 파일 인풋 핸들러

  const fileInputHandler = (e) => {
    const inputKey = e.target.id.split("-")[0];
    const newFile = e.target.files[0];
    setWorkData((prev) => {
      return {
        ...prev,
        projectInfoDto: {
          ...prev.projectInfoDto,
          [inputKey]: {
            ...prev.projectInfoDto[inputKey],
            file: newFile,
            fileName: newFile.name,
          },
        },
      };
    });
    e.target.value = null;
  };

  const fileDeleteHandler = (key) => {
    setWorkData((prev) => {
      return {
        ...prev,
        projectInfoDto: {
          ...prev.projectInfoDto,
          [key]: {
            file: null,
            fileName: "",
            fileType: "",
            fileUrl: "",
            id: 0,
          },
        },
      };
    });
  };

  // files 관리

  const fileMove = (fromIndex, toIndex) => {
    const updatedfiles = [...workData.projectInfoDto.files];
    const [movedfile] = updatedfiles.splice(fromIndex, 1);
    updatedfiles.splice(toIndex, 0, movedfile);
    setWorkData((prev) => {
      return {
        ...prev,
        projectInfoDto: { ...prev.projectInfoDto, files: updatedfiles },
      };
    });
  };

  const filesListDeleteHandler = (idx) => {
    const updatedfiles = [...workData.projectInfoDto.files];
    updatedfiles.splice(idx, 1);
    setWorkData((prev) => {
      return {
        ...prev,
        projectInfoDto: { ...prev.projectInfoDto, files: updatedfiles },
      };
    });
  };

  const filesListEditinputHandler = (e, idx) => {
    const editedfile = e.target.files[0];

    const editfile = {
      file: editedfile,
      fileName: editedfile.name,
      seq: idx,
    };

    setWorkData((prev) => {
      const updatedFiles = [...prev.projectInfoDto.files];

      updatedFiles[idx] = editfile;

      return {
        ...prev,
        projectInfoDto: { ...prev.projectInfoDto, files: updatedFiles },
      };
    });

    e.target.value = null;
  };

  const filesListAddinputHandler = () => {
    const addfile = {
      file: null,
      fileName: "파일을 입력해주세요.",
    };

    setWorkData((prev) => {
      return {
        ...prev,
        projectInfoDto: {
          ...prev.projectInfoDto,
          files: [...prev.projectInfoDto.files, addfile],
        },
      };
    });
  };

  const FilesList = (
    <div>
      {workData.projectInfoDto.files.map((file, idx) => {
        return (
          <div className='list-div'>
            <DraggableItem
              key={file.id}
              index={idx}
              item={file}
              moveItem={fileMove}
              draggable={!inputDisabled.files}
            >
              <label htmlFor={`files-${idx}`}>
                {file.fileName ? file.fileName : "파일을 입력해주세요"}
                {!inputDisabled.files && <FileOutlined />}
                <input
                  type='file'
                  className='file-input'
                  id={`files-${idx}`}
                  onChange={(e) => filesListEditinputHandler(e, idx)}
                  disabled={inputDisabled.files}
                />
              </label>
              {!inputDisabled.files && (
                <DeleteOutlined
                  id='files-delete'
                  onClick={() => filesListDeleteHandler(idx)}
                />
              )}
            </DraggableItem>
          </div>
        );
      })}
    </div>
  );

  const isFirst = workData.projectInfoDto.id === 0;

  return (
    <WorkDetailWrapper>
      <div>
        <h1>
          디테일
          {isFirst ? (
            <img
              src='/assets/check.svg'
              alt=''
              onClick={() => setEditModal(true)}
            />
          ) : (
            <></>
          )}
        </h1>
      </div>
      <div>
        <h4>
          프로젝트 이름
          <div>
            {isFirst ? null : inputDisabled.projectName ? (
              <img
                id='projectName-able'
                src='/assets/Edit.svg'
                alt=''
                onClick={inputAbleHandler}
              />
            ) : (
              <>
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={inputAbleHandler}
                />
              </>
            )}
          </div>
        </h4>
        <input
          type='text'
          id='projectName'
          value={workData.projectInfoDto.projectName}
          disabled={inputDisabled.projectName}
          onChange={inputHandler}
        />
      </div>
      <div>
        <h4>
          프로젝트 엔드 데이트
          <div>
            {isFirst ? null : inputDisabled.endDate ? (
              <img
                id='endDate-able'
                src='/assets/Edit.svg'
                alt=''
                onClick={inputAbleHandler}
              />
            ) : (
              <>
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={inputAbleHandler}
                />
              </>
            )}
          </div>
        </h4>
        <input
          type='text'
          value={workData.projectInfoDto.endDate}
          disabled={inputDisabled.endDate}
          id='endDate'
          onChange={inputHandler}
        />
      </div>
      <div>
        <h4>
          클라이언트
          <div>
            {isFirst ? null : inputDisabled.clientName ? (
              <img
                id='clientName-able'
                src='/assets/Edit.svg'
                alt=''
                onClick={inputAbleHandler}
              />
            ) : (
              <>
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={inputAbleHandler}
                />
              </>
            )}
          </div>
        </h4>
        <input
          type='text'
          value={workData.projectInfoDto.clientName}
          disabled={inputDisabled.clientName}
          id='clientName'
          onChange={inputHandler}
        />
      </div>
      <div>
        <h4>
          디자인 실무자 리스트
          <div>
            {isFirst ? (
              <img src='/assets/Add.svg' alt='' onClick={addDesingerHandler} />
            ) : inputDisabled.designer ? (
              <img
                id='designer-able'
                src='/assets/Edit.svg'
                alt=''
                onClick={inputAbleHandler}
              />
            ) : (
              <>
                <img
                  src='/assets/Add.svg'
                  alt=''
                  onClick={addDesingerHandler}
                />
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={inputAbleHandler}
                />
              </>
            )}
          </div>
        </h4>
        <div>
          {designers.map((des, idx) => {
            return (
              <AttendListitem key={des.id ? "des" + des.id : "des-new" + idx}>
                {!inputDisabled.designer && (
                  <DraggableItem
                    index={idx}
                    item={des}
                    moveItem={(fromIndex, toIndex) =>
                      attendMove(fromIndex, toIndex, "Designer")
                    }
                  >
                    <MenuOutlined />
                  </DraggableItem>
                )}
                <select
                  name={"des" + des.name + des.id}
                  id={"des" + des.name + des.id}
                  value={des.designerType}
                  onChange={(e) => handleSelectChange(e, "designer", idx)}
                  disabled={inputDisabled.designer}
                >
                  {designerOptions.map((option, index) => (
                    <option value={option} key={index}>
                      {option}
                    </option>
                  ))}
                </select>
                <input
                  className='list-input'
                  id={"des" + des.name + des.id}
                  type='text'
                  value={des.name}
                  disabled={inputDisabled.designer}
                  onChange={(event) =>
                    attendInputHandler(event, "designer", idx)
                  }
                />
                {!inputDisabled.designer && (
                  <DeleteOutlined
                    disabled={inputDisabled.designer}
                    onClick={() => handleRemove("designer", idx)}
                  />
                )}
              </AttendListitem>
            );
          })}
        </div>
      </div>
      <div>
        <h4>
          개발 실무자 리스트
          <div>
            {isFirst ? (
              <img src='/assets/Add.svg' alt='' onClick={addDeveloperHandler} />
            ) : inputDisabled.developer ? (
              <img
                id='developer-able'
                src='/assets/Edit.svg'
                alt=''
                onClick={inputAbleHandler}
              />
            ) : (
              <>
                <img
                  src='/assets/Add.svg'
                  alt=''
                  onClick={addDeveloperHandler}
                />
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={inputAbleHandler}
                />
              </>
            )}
          </div>
        </h4>
        <div>
          {developers.map((dev, idx) => {
            return (
              <AttendListitem key={dev.id ? "dev" + dev.id : "dev-new" + idx}>
                {!inputDisabled.developer && (
                  <DraggableItem
                    index={idx}
                    item={dev}
                    moveItem={(fromIndex, toIndex) =>
                      attendMove(fromIndex, toIndex, "Developer")
                    }
                  >
                    <MenuOutlined />
                  </DraggableItem>
                )}
                <select
                  name={"dev" + dev.name + dev.id}
                  id={"dev" + dev.name + dev.id}
                  value={dev.developerType}
                  onChange={(e) => handleSelectChange(e, "developer", idx)}
                  disabled={inputDisabled.developer}
                >
                  {developerOptions.map((option, index) => (
                    <option value={option} key={index}>
                      {option}
                    </option>
                  ))}
                </select>
                <input
                  className='list-input'
                  id={"dev" + dev.name + dev.id}
                  type='text'
                  value={dev.name}
                  disabled={inputDisabled.developer}
                  onChange={(event) =>
                    attendInputHandler(event, "developer", idx)
                  }
                />
                {!inputDisabled.developer && (
                  <DeleteOutlined
                    disabled={inputDisabled.developer}
                    onClick={() => handleRemove("developer", idx)}
                  />
                )}
              </AttendListitem>
            );
          })}
        </div>
      </div>
      <div>
        <h4>
          워크 썸네일
          <div>
            {isFirst ? null : inputDisabled.mainfile ? (
              <img
                id='mainfile-able'
                src='/assets/Edit.svg'
                alt=''
                onClick={inputAbleHandler}
              />
            ) : (
              <>
                {/* {workData.projectInfoDto.mainFile.id === 0 && (
                <img
                  src='/assets/Add.svg'
                  alt=''
                  onClick={addDeveloperHandler}
                />
              )} */}
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={inputAbleHandler}
                />
              </>
            )}
          </div>
        </h4>
        <div className='list-div'>
          <label htmlFor='mainFile-input'>
            {workData.projectInfoDto.mainFile.fileName || "파일을 입력해주세요"}
            {!inputDisabled.mainfile && <FileOutlined />}
            <input
              id='mainFile-input'
              type='file'
              className='file-input'
              onChange={fileInputHandler}
              disabled={inputDisabled.mainfile}
            />
          </label>
          {!inputDisabled.mainfile && (
            <DeleteOutlined
              id='mainFile-delete'
              onClick={() => fileDeleteHandler("mainFile")}
            />
          )}
        </div>
      </div>
      <div>
        <h4>
          메인 썸네일
          <div>
            {isFirst ? null : inputDisabled.workfile ? (
              <img
                id='workfile-able'
                src='/assets/Edit.svg'
                alt=''
                onClick={inputAbleHandler}
              />
            ) : (
              <>
                {/* {workData.projectInfoDto.workFile.id === 0 && (
                <img
                  src='/assets/Add.svg'
                  alt=''
                  onClick={addDeveloperHandler}
                />
              )} */}
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={inputAbleHandler}
                />
              </>
            )}
          </div>
        </h4>
        <div className='list-div'>
          <label htmlFor='workFile-input'>
            {workData.projectInfoDto.workFile.fileName || "파일을 입력해주세요"}
            {!inputDisabled.workfile && <FileOutlined />}
            <input
              id='workFile-input'
              type='file'
              className='file-input'
              onChange={fileInputHandler}
              disabled={inputDisabled.workfile}
            />
          </label>
          {!inputDisabled.workfile && (
            <DeleteOutlined
              id='workFile-delete'
              onClick={() => fileDeleteHandler("workFile")}
            />
          )}
        </div>
      </div>
      <div>
        <h4>
          이미지들
          <div>
            {isFirst ? (
              <img
                src='/assets/Add.svg'
                alt=''
                onClick={filesListAddinputHandler}
              />
            ) : inputDisabled.files ? (
              <img
                id='files-able'
                src='/assets/Edit.svg'
                alt=''
                onClick={inputAbleHandler}
              />
            ) : (
              <>
                <img
                  src='/assets/Add.svg'
                  alt=''
                  onClick={filesListAddinputHandler}
                />
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setEditModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={inputAbleHandler}
                />
              </>
            )}
          </div>
        </h4>
        {FilesList}
      </div>
      <Modal
        isYesNo={true}
        onClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        // onYesClick={() => deleteHandler()}
      >
        삭제 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setCancelModal(false)}
        isOpen={cancelModal}
        onYesClick={() => resetHandler(ableKey)}
      >
        취소 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setEditModal(false)}
        isOpen={editModal}
        onYesClick={editHandler}
      >
        수정 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </WorkDetailWrapper>
  );
};

export default WorkDetail;

const WorkDetailWrapper = styled.div`
  margin-bottom: 30px;

  h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    max-width: 600px;

    > div {
      display: flex;
      align-items: end;

      img {
        margin-left: 10px;
      }
    }
  }

  img {
    width: 25px;
    height: 25px;
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .file-input {
    display: none;
  }

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 30px;
    border: 1px solid #ffffff;
    padding: 5px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
  li {
    list-style: none;
  }

  input {
    height: 30px;
    width: 100%;
    max-width: 600px;

    border: 1px solid #ffffff;

    background-color: transparent;
    color: white;

    &:disabled {
      background-color: transparent;
      color: white;
    }
  }

  select {
    width: 146px;
    height: 30px;
    border: #ffffff 1px solid;
    background-color: #000000;
    color: white;
    &:disabled {
      background-color: black;
      color: white;
    }
    margin-right: 5px;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .list-input {
    width: 100%;
    max-width: 450px;
  }

  .list-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
`;

const AttendListitem = styled.div`
  display: flex;
  align-items: center;

  li {
    width: fit-content;
  }
`;
