import { useEffect, useState } from "react";
import {
  ProjcetBudgetGetAPI,
  ProjcetPeriodGetAPI,
  ProjcetSearchGetAPI,
  ProjcetStatusGetAPI,
  ProjcetTypeGetAPI,
} from "../../api/ConnectApi";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const CustomerProject = () => {
  const [options, setOptions] = useState({
    projectBudget: [],
    projectPeriod: [],
    projectPreparingStatus: [],
    projectRequestType: [],
  });

  const [filters, setFilters] = useState({
    keyword: "",
    projectBudget: "",
    projectPeriod: "",
    projectPreparingStatus: "",
    projectRequestType: "",
  });

  const [filterOpen, setFilterOpen] = useState(false);

  const [requsetList, setRequestList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([
      ProjcetBudgetGetAPI(),
      ProjcetPeriodGetAPI(),
      ProjcetStatusGetAPI(),
      ProjcetTypeGetAPI(),
    ]).then((response) => {
      setOptions({
        projectBudget: response[0].data,
        projectPeriod: response[1].data,
        projectPreparingStatus: response[2].data,
        projectRequestType: response[3].data,
      });
    });
    ProjcetSearchGetAPI(filters)
      .then((response) => setRequestList(response.data.dataList))
      .catch((error) => console.log(error));
  }, []);

  const optionChangeHandler = (e) => {
    const filterKey = e.target.id.split("-")[0];
    setFilters((prev) => {
      return { ...prev, [filterKey]: e.target.value };
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    ProjcetSearchGetAPI(filters)
      .then((response) => setRequestList(response.data.dataList))
      .catch((error) => console.log(error));
  };

  const toDetailHandler = (id) => {
    navigate(`/contact/project/${id}`);
  };

  const projectBudgetSelect = (
    <select
      value={filters.projectBudget}
      id='projectBudget-Select'
      onChange={optionChangeHandler}
    >
      <option value=''>전체</option>
      {options.projectBudget.map((option, idx) => {
        return (
          <>
            <option value={option} key={"option-" + option}>
              {option}
            </option>
          </>
        );
      })}
    </select>
  );
  const projectPeriodSelect = (
    <select
      value={filters.projectPeriod}
      id='projectPeriod-Select'
      onChange={optionChangeHandler}
    >
      <option value=''>전체</option>
      {options.projectPeriod.map((option, idx) => {
        return (
          <option value={option} key={"option-" + option}>
            {option}
          </option>
        );
      })}
    </select>
  );
  const projectPreparingStatusSelect = (
    <select
      value={filters.projectPreparingStatus}
      id='projectPreparingStatus-Select'
      onChange={optionChangeHandler}
    >
      <option value=''>전체</option>
      {options.projectPreparingStatus.map((option, idx) => {
        return (
          <option value={option} key={"option-" + option}>
            {option}
          </option>
        );
      })}
    </select>
  );
  const projectRequestTypeSelect = (
    <select
      value={filters.projectRequestType}
      id='projectRequestType-Select'
      onChange={optionChangeHandler}
    >
      <option value=''>전체</option>
      {options.projectRequestType.map((option, idx) => {
        return (
          <option value={option} key={"option-" + option}>
            {option}
          </option>
        );
      })}
    </select>
  );

  const projectRequestList = (
    <>
      <table>
        <thead>
          <tr className='request-header'>
            <th className='project-type-header'>프로젝트 분야</th>
            <th className='project-name-header'>프로젝트 이름</th>
            <th className='project-prepatration-header'>프로젝트 준비현황</th>
            <th className='project-period-header'>프로젝트 기간</th>
            <th className='project-budget-header'>프로젝트 예산</th>
            <th className='project-manager-header'>담당자명/직책</th>
            <th className='project-client-header'>기관 또는 기업명</th>
          </tr>
        </thead>
      </table>
      <table>
        <tbody className={filterOpen ? "open" : ""}>
          {requsetList.map((request, idx) => {
            return (
              <tr
                className='request-data'
                onClick={() => toDetailHandler(request.id)}
              >
                <td className='project-type-body'>
                  {request.projectRequestType}
                </td>
                <td className='project-name-body'>{request.projectName}</td>
                <td className='project-prepatration-body'>
                  {request.projectPreparationStatus}
                </td>
                <td className='project-period-body'>{request.projectPeriod}</td>
                <td className='project-budget-body'>{request.projectBudget}</td>
                <td className='project-manager-body'>
                  {request.managerNameAndPosition}
                </td>
                <td className='project-client-body'>{request.clientName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );

  return (
    <CustomerProjectWrapper>
      <div className='list-header'>
        <h4>프로젝트 의뢰 리스트</h4>
        <img
          src='/assets/Dropdown.svg'
          alt=''
          onClick={() => setFilterOpen((prev) => !prev)}
        />
      </div>
      <form onSubmit={searchHandler} className={filterOpen ? "open" : ""}>
        <div>
          <p>키워드</p>
          <input
            type='text'
            id={"keyword-Input"}
            onChange={optionChangeHandler}
          />
        </div>
        <div>
          <p>프로젝트 종류</p>
          {projectRequestTypeSelect}
        </div>
        <div>
          <p>프로젝트 예산</p>
          {projectBudgetSelect}
        </div>
        <div>
          <p>프로젝트 기간</p>
          {projectPeriodSelect}
        </div>
        <div>
          <p>프로젝트 준비 현황</p>
          {projectPreparingStatusSelect}
        </div>
        <button type='submit'>Search</button>
      </form>

      {requsetList.length !== 0 ? (
        projectRequestList
      ) : (
        <p>검색 결과가 존재하지 않습니다.</p>
      )}
    </CustomerProjectWrapper>
  );
};

export default CustomerProject;

const CustomerProjectWrapper = styled.div`
  select {
    width: 146px;
    height: 30px;
    border: #ffffff 1px solid;
    background-color: #000000;
    color: white;
    margin-right: 5px;
  }

  form {
    display: flex;
    align-items: end;
    height: 0;
    transition: 0.5s;
    overflow: hidden;
    padding: 0px 5px;

    &.open {
      height: 60px;
      padding: 5px 5px;
    }
  }

  input {
    width: 300px;
    height: 30px;
    border: #ffffff 1px solid;
    background-color: #000000;
    color: white;
    &:disabled {
      background-color: black;
      color: white;
    }
    margin-right: 5px;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .list-header {
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;
    }
  }

  table {
    margin-top: 20px;
  }

  .request-header {
    th {
      padding: 10px;
      font-size: 20px;
      font-weight: bold;

      width: 200px;

      border-bottom: 1px solid #ffffff;
    }
    .project-type-header {
      width: 300px;
    }
    .project-name-header {
      width: 200px;
    }
    .project-prepatration-header {
      width: 200px;
    }
    .project-period-header {
      width: 150px;
    }
    .project-budget-header {
      width: 150px;
    }
    .project-manager-header {
      width: 150px;
    }
    .project-client-header {
      width: 180px;
    }
  }

  .request-data {
    transition: 0.2s ease-in;
    &:hover {
      background-color: #707070;
    }
    td {
      padding: 20px 5px 20px 10px;
      font-size: 18px;
      font-weight: bold;
      white-space: pre-line;
      /* border-left: #707070 1px solid;

      &:last-child {
        border-right: #707070 1px solid;
      } */
    }
    .project-type-body {
      width: 300px;
    }
    .project-name-body {
      width: 200px;
    }
    .project-prepatration-body {
      width: 200px;
    }
    .project-period-body {
      width: 150px;
    }
    .project-budget-body {
      width: 150px;
    }
    .project-manager-body {
      width: 150px;
    }
    .project-client-body {
      width: 180px;
    }
  }

  thead {
    display: flex;
    width: 1350px;
  }

  tbody {
    display: flex;
    flex-direction: column;
    width: 1350px;
    height: calc(100vh - 340px);
    overflow-y: scroll;

    transition: 0.5s;

    &.open {
      height: calc(100vh - 410px);
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      height: 30px;
      background-color: #707070;
      border-radius: 10px;
    }
  }
`;
