import { useEffect, useState } from "react";
import { loginAPI } from "../api/api";
import { useSetRecoilState } from "recoil";
import AuthState from "../store/AuthStore";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import Modal from '../components/Modal/Modal';

const LoginPage = () => {
  const setAuthState = useSetRecoilState(AuthState);
  const [modalState, setModalState] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    userId: "",
    password: "",
  });

  const idInputHandler = (e) => {
    setLoginData((prev) => {
      return { ...prev, userId: e.target.value };
    });
  };
  const passwordInputHandler = (e) => {
    setLoginData((prev) => {
      return { ...prev, password: e.target.value };
    });
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await loginAPI(loginData);
      if (response.data.code === 400) {
        throw new Error(response.data.message);
      }
      setAuthState(response.data.data.token);
      navigate("/");
    } catch (error) {
      setModalState(true)
    }
  };

  return (
    <LoginStyle>
      <form onSubmit={loginHandler}>
        <h1>Login</h1>
        <input
          type='text'
          onChange={idInputHandler}
          value={loginData.userId}
          placeholder='아이디를 입력해주세요.'
        />
        <div className='passwordwrapper'>
          <input
            type={passwordType}
            onChange={passwordInputHandler}
            value={loginData.password}
            placeholder='비밀번호를 입력해주세요.'
          />
          {passwordType == "password" ? <EyeInvisibleFilled onClick={() => setPasswordType("text")}/> : <EyeFilled onClick={() => setPasswordType("password")}/>}
        </div>
        <button type='submit'>Login</button>
      </form>
      <Modal isYesNo={false} isOpen={modalState} onClose={() => setModalState(false)}> 
        로그인 실패
      </Modal>
    </LoginStyle>
  );
};

export default LoginPage;

const LoginStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 100px);

  form {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  input {
    width: 100%;
    height: 30px;
    color: #ffffff;

    border: #707070 1px solid;
    background-color: transparent;

    margin-bottom: 20px;

    &:focus {
      border: #ffffff;
    }
  }

  button {
    background-color: transparent;
    color: #ffffff;
    border: #ffffff solid 1px;
    height: 40px;
    width: 200px;

    font-size: 20px;
  }

  .passwordwrapper {
    position: relative;
    width: 100%;

    .anticon {
      position: absolute;

      display: flex;
      align-items: center;

      height: 30px;
      top: 0;
      right: 5px;

      color: #707070;
    }
  }
`;
