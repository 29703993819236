import _ from "lodash";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState, Fragment } from "react";
import { styled } from "styled-components";
import Modal from "../Modal/Modal";
import {
  BackgroundDeleteAPI,
  BackgroundPostAPI,
  BackgroundPutAPI,
  BackgroundSeqGetAPI,
  BackgroundSeqListGetAPI,
} from "../../api/MainApi";
import { useSetRecoilState } from "recoil";
import { LoadingAtom } from "../../store/LoadingStore";

const BGList = ({
  ListName,
  localListItem,
  editState,
  editFileHandler,
  editNameHandler,
  editSequenceHandler,
  deleteModalHandler,
  index,
}) => {
  const [seqList, setSeqList] = useState([]);

  useEffect(() => {
    const fetchSequence = async () => {
      BackgroundSeqGetAPI(localListItem.id, ListName)
        .then((response) => {
          setSeqList(response.data.dataList);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchSequence();
  }, []);

  return (
    <Fragment key={ListName + localListItem.sequence}>
      <ListItemWrapper key={ListName + localListItem.sequence}>
        <div className='li-content'>
          {editState && (
            <select
              value={localListItem.sequence}
              onChange={(e) => {
                editSequenceHandler(index, e);
              }}
            >
              {seqList.map((num, idx) => {
                return (
                  <option value={num} key={ListName + localListItem.name + idx}>
                    {num}
                  </option>
                );
              })}
            </select>
          )}
          <div>
            {editState ? (
              <input
                className='nameHandler'
                type='text'
                value={localListItem.backgroundName}
                onChange={(e) => {
                  editNameHandler(index, e);
                }}
              />
            ) : (
              <p>{localListItem.backgroundName}</p>
            )}

            <p>file: {localListItem.fileName}</p>
          </div>
        </div>
        {editState && (
          <div>
            <label htmlFor={`editfile-${index}`}>
              <EditOutlined />
              <input
                className='file-input'
                type='file'
                id={`editfile-${index}`}
                onChange={(e) => editFileHandler(index, e)}
              />
            </label>
            <DeleteOutlined onClick={() => deleteModalHandler(localListItem)} />
          </div>
        )}
      </ListItemWrapper>
    </Fragment>
  );
};

const BackgroundList = ({ ListName, List, onRefetch }) => {
  const [localList, setLocalList] = useState([]);

  const [postList, setPostList] = useState(null);
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);

  const [deleteItem, setDeleteItem] = useState(null);

  // 로딩
  const setLoading = useSetRecoilState(LoadingAtom);

  // 모달 상태
  const [deleteModal, setDeleteModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const [backgroundSequence, setBackgroundSequence] = useState([]);

  useEffect(() => {
    BackgroundSeqListGetAPI(ListName)
      .then((res) => {
        setBackgroundSequence(res.data.dataList);
      })
      .catch((error) => console.log(error));
  }, []);

  // 삭제 모달을 띄우는 핸들러
  const deleteModalHandler = (item) => {
    setDeleteItem(item);
    setDeleteModal(true);
  };

  const editNameHandler = (index, event) => {
    setLocalList((prevList) => {
      const updatedList = [...prevList];
      const item = updatedList[index];
      updatedList[index] = {
        ...item,
        backgroundName: event.target.value,
      };
      return updatedList;
    });
  };

  const editSequenceHandler = (index, event) => {
    setLocalList((prevList) => {
      const updatedList = [...prevList];
      const item = updatedList[index];
      updatedList[index] = {
        ...item,
        sequence: event.target.value,
      };
      return updatedList;
    });
  };

  const editFileHandler = (index, event) => {
    // 입력에서 선택한 파일을 가져옵니다.
    const file = event.target.files[0];
    if (file) {
      // localList의 해당 인덱스 항목을 업데이트합니다.
      setLocalList((prevList) => {
        const updatedList = [...prevList];
        const item = updatedList[index];
        updatedList[index] = {
          ...item,
          file: file, // 파일 객체를 추가합니다.
          fileName: file.name, // 파일 이름을 업데이트합니다.
        };
        return updatedList;
      });
    }
    event.target.value = null;
  };

  const deleteHandler = async () => {
    try {
      await BackgroundDeleteAPI(deleteItem.id);
    } catch (error) {
      setErrorModal(true);
      return;
    }
    setCompleteModal(true);
    setDeleteItem(null);
    setDeleteModal(false);
    onRefetch(true);
  };

  const editHandler = async () => {
    setLoading(true);
    const updatedItems = [];

    localList.forEach((localItem, index) => {
      if (JSON.stringify(localItem) !== JSON.stringify(List[index])) {
        updatedItems.push(localItem);
      }
    });

    const updatePromises = updatedItems.map((item) =>
      BackgroundPutAPI(item.id, item)
        .then(() => {
          setCompleteModal(true);
        })
        .catch((error) => {
          setErrorModal(true)
        })
    );

    Promise.all(updatePromises)
      .then(() => {
        setEditState(false);
        setEditModal(false);
        setCompleteModal(true);
        onRefetch(true);
        setLocalList(_.cloneDeep(List));
      })
      .catch(() => {
        console.error("Some updates failed.");
        setErrorModal(true);
        onRefetch(true);
        setLocalList(_.cloneDeep(List));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 추가 작업
  const addHandler = async () => {
    setLoading(true);
    BackgroundPostAPI(postList)
      .then(() => {
        setCompleteModal(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorModal(true);
      })
      .finally(() => {
        setLoading(false);
        setPostList(null);
        setAddState(false);

        onRefetch();
      });
  };

  // 추가 작업을 취소
  const addStateClose = () => {
    setLocalList(_.cloneDeep(List));
    setPostList(null);
    setEditState(false);
    setAddState(false);
  };

  // 파일을 추가하는 핸들러, post에 파일을 추가
  const fileChangeHandler = (e) => {
    const newFile = e.target.files[0];
    const newSequence = localList.length + 1;
    const newBg = {
      fileName: newFile.name,
      backgroundName: newFile.name,
      backgroundType: ListName,
      sequence: backgroundSequence[0],
      file: newFile,
    };
    setPostList(newBg);
    e.target.value = null;
  };

  // 로컬 리스트를 통해 컴포넌트를 렌더링
  useEffect(() => {
    setLocalList(_.cloneDeep(List));
  }, [List]);

  const BGLists = localList.map((item, index) => {
    return (
      <BGList
        ListName={ListName}
        localListItem={item}
        addState={addState}
        editState={editState}
        editFileHandler={editFileHandler}
        editNameHandler={editNameHandler}
        editSequenceHandler={editSequenceHandler}
        deleteModalHandler={deleteModalHandler}
        index={index}
      ></BGList>
    );
  });

  return (
    <BgListWrapper>
      <div className='bg-header'>
        <h2>{ListName}</h2>
        <img
          src='/assets/Add.svg'
          alt=''
          onClick={() => {
            setAddState(true);
            setEditState(false);
          }}
        />
        <img
          src='/assets/Setting.svg'
          alt=''
          onClick={() => {
            setEditState(true);
            setAddState(false);
          }}
        />
      </div>
      <hr />
      {BGLists}
      {addState && (
        <>
          <AddItemWrapper>
            <label htmlFor='addNewBg'>
              {postList ? <p>{postList.fileName}</p> : <p>새로 추가 할 파일</p>}
              <img src='/assets/Save.svg' alt='' />
              <input
                className='file-input'
                type='file'
                id='addNewBg'
                onChange={fileChangeHandler}
              />
            </label>
            <div className='buttonwrapper'>
              <img
                src='/assets/check.svg'
                alt=''
                onClick={() => setAcceptModal(true)}
              />
              <img
                src='/assets/close.svg'
                alt=''
                onClick={() => setCancelModal(true)}
              />
            </div>
          </AddItemWrapper>
        </>
      )}
      {editState && (
        <AddItemWrapper>
          <div className='buttonwrapper'>
            <img
              src='/assets/check.svg'
              alt=''
              onClick={() => setEditModal(true)}
            />
            <img
              src='/assets/close.svg'
              alt=''
              onClick={() => setCancelModal(true)}
            />
          </div>
        </AddItemWrapper>
      )}
      <Modal
        isYesNo={true}
        onClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        onYesClick={() => deleteHandler()}
      >
        삭제 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setCancelModal(false)}
        isOpen={cancelModal}
        onYesClick={addStateClose}
      >
        취소 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setAcceptModal(false)}
        isOpen={acceptModal}
        onYesClick={addHandler}
      >
        추가 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setEditModal(false)}
        isOpen={editModal}
        onYesClick={editHandler}
      >
        수정 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </BgListWrapper>
  );
};

export default BackgroundList;

const BgListWrapper = styled.div`
  .bg-header {
    display: flex;
    flex-direction: row;
    align-items: end;

    img {
      width: 20px;
      height: 20px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }

  hr {
    margin-left: 0;
    width: 200px;
  }
`;

const ListItemWrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 50%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #707070;

  font-size: 20px;

  .file-input {
    display: none;
  }

  .anticon {
    margin-left: 10px;
  }

  .li-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    select {
      height: 20px;
      margin-right: 10px;
    }
  }
`;

const AddItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  label {
    display: flex;
    justify-content: space-between;
    width: 100%;

    padding: 10px;

    border: 1px #ffffff solid;
  }

  .file-input {
    display: none;
  }

  .buttonwrapper {
    display: flex;
    justify-content: end;

    width: 100%;

    padding: 10px;
    img {
      width: 40px;
      height: 40px;
      margin-left: 20px;
    }
  }
`;
