import { styled } from "styled-components";

import { useEffect, useState } from "react";

import Modal from '../Modal/Modal';
import WorkArea from './WorkArea';
import { ProjcetAreaPostAPI, ProjectAreaGetAPI } from '../../api/WorkApi';


const WorkAreaList = () => {
  const [workList, setWorkList] = useState({ full: [], two: [] });
  const [areaList, setAreaList] = useState([]);
  const [refetch, setRefecth] = useState(true);
  const [gridAreaType, setGridAreaType] = useState("full");

  useEffect(() => {
    if (refetch) {
      ProjectAreaGetAPI()
        .then((res) => {
          const dataList = res.data.dataList;

          const setList = {
            full: [],
            two: [],
          };

          dataList.forEach((data) => {
            if (data.gridAreaType === "full") {
              setList.full.push(data);
            } else {
              setList.two.push(data);
            }
          });

          Object.keys(setList).forEach((key) => {
            setList[key].sort((a, b) => a.projectAreaId - b.projectAreaId);
          });

          setWorkList(setList);
        })
        .catch((error) => console.log(error));
      setRefecth(false);
    }
  }, [refetch]);

  useEffect(() => {
    const list = workList;

    const result = [];

    let fullIndex = 0;
    let twoIndex = 0;

    while (fullIndex < list.full.length || twoIndex < list.two.length) {
      if (fullIndex < list.full.length) {
        result.push(list.full[fullIndex]);
        fullIndex++;
      }

      for (let i = 0; i < 2 && twoIndex < list.two.length; i++) {
        result.push(list.two[twoIndex]);
        twoIndex++;
      }
    }
    setAreaList(result);
  }, [workList]);

  const [acceptModal, setAcceptModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const typeChangeHandler = (e) => {
    setGridAreaType(e.target.value);
  };

  const addHandler = () => {
    ProjcetAreaPostAPI(gridAreaType)
      .then(() => {
        setCompleteModal(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorModal(true);
      })
      .finally(() => {
        setGridAreaType("full");
        setRefecth(true);
      });
  };
  return (
    <>
      <div className='main-header'>
        <h3>ProjectList</h3>
        <img
          src='/assets/Add.svg'
          alt=''
          onClick={() => setAcceptModal(true)}
        />
      </div>

      <WorkArea areaList={areaList} onRefetch={() => setRefecth(true)} />
      <Modal
        isYesNo={true}
        onClose={() => {
          setGridAreaType("full");
          setAcceptModal(false);
        }}
        isOpen={acceptModal}
        onYesClick={addHandler}
      >
        <RadioButtonWrapper>
          <h1>Project 등록 영역 추가</h1>
          <div>
            <input
              type='radio'
              id='full'
              name='gridtype'
              value='full'
              checked={gridAreaType === "full"}
              onChange={typeChangeHandler}
            />
            <label htmlFor='full'>Full</label>
          </div>
          <div>
            <input
              type='radio'
              id='two'
              name='gridtype'
              value='two'
              checked={gridAreaType === "two"}
              onChange={typeChangeHandler}
            />
            <label htmlFor='two'>2분할</label>
          </div>
          <div>로 생성하시겠습니까?</div>
        </RadioButtonWrapper>
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setAcceptModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </>
  );
};

export default WorkAreaList;

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
