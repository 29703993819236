import { useEffect, useRef, useState } from "react";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import Modal from "../Modal/Modal";
import { ServiceDeleteAPI, ServicePutAPI } from "../../api/AboutApi";
import { useDrag, useDrop } from "react-dnd";
import _ from "lodash";
import { useSetRecoilState } from "recoil";
import { LoadingAtom } from "../../store/LoadingStore";

const DRAG_TYPE = "ITEM";

const DraggableItem = ({ item, index, moveItem, children }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: DRAG_TYPE,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAG_TYPE,
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <li ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </li>
  );
};

const ServiceUpdate = ({
  servicelist,
  selectCategory,
  onRefetch,
  onEditState,
}) => {
  // 로딩
  const setLoading = useSetRecoilState(LoadingAtom);
  // 모달 상태
  const [deleteModal, setDeleteModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const [updateList, setUpdateList] = useState({});
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    console.log(servicelist);
    setUpdateList(_.cloneDeep(servicelist));
  }, [servicelist]);

  const deleteHandler = (id) => {
    ServiceDeleteAPI(id)
      .then((res) => {
        setCompleteModal(true);
      })
      .catch((error) => {
        setErrorModal(true);
      })
      .finally(() => {
        setDeleteModal(false);
        onRefetch(true);
      });
  };

  const updateHandeler = (e, category, idx) => {
    const updatedCategoryArray = [...servicelist[category]];

    updatedCategoryArray[idx].content = e.target.value;

    setUpdateList({
      ...servicelist,
      [category]: updatedCategoryArray,
    });
  };

  const moveItem = (fromIndex, toIndex) => {
    const updatedItems = [...servicelist[selectCategory]];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setUpdateList({ ...servicelist, [selectCategory]: updatedItems });
  };

  console.log(updateList);

  const UpdateLists =
    servicelist[selectCategory] &&
    servicelist[selectCategory].map((item, idx) => {
      return (
        <DraggableItem
          key={item.id}
          index={idx}
          item={item}
          moveItem={moveItem}
        >
          <MenuOutlined />
          <input
            type='text'
            value={item.content}
            onChange={(e) => {
              updateHandeler(e, selectCategory, idx);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              setDeleteItem(item.id);
              setDeleteModal(true);
            }}
          />
        </DraggableItem>
      );
    });

  const PutHandler = async () => {
    setLoading(true);
    const changedProperties = [];

    _.forOwn(servicelist, (value, key) => {
      if (!_.isEqual(value, servicelist[key])) {
        changedProperties.push(key);
      }
    });

    for (let changedProperty of changedProperties) {
      const updatedItems = servicelist[changedProperty].map((item, index) => ({
        ...item,
        sequence: 100000 + index + 1, // 인덱스는 0부터 시작하므로 1을 더해줍니다.
      }));

      for (let data of updatedItems) {
        try {
          await ServicePutAPI(data);
        } catch (error) {
          console.log(error);
          setErrorModal(true);
          return;
        }
      }

      const secondUpdatedItems = servicelist[changedProperty].map(
        (item, index) => ({
          ...item,
          sequence: index + 1, // 인덱스는 0부터 시작하므로 1을 더해줍니다.
        })
      );

      for (let data of secondUpdatedItems) {
        try {
          await ServicePutAPI(data);
        } catch (error) {
          console.log(error);
          setErrorModal(true);
          return;
        }
      }
    }
    setLoading(false);
    onRefetch(true);
    onEditState(false);
    setCompleteModal(true);
  };

  return (
    <>
      <ul>{UpdateLists}</ul>

      <div className='edit-button'>
        <img
          src='/assets/check.svg'
          alt=''
          onClick={() => setEditModal(true)}
        />
        <img
          src='/assets/close.svg'
          alt=''
          onClick={() => setCancelModal(true)}
        />
      </div>
      <Modal isOpen={errorModal} onClose={() => setErrorModal(false)}>
        에러 발생
      </Modal>
      <Modal isOpen={completeModal} onClose={() => setCompleteModal(false)}>
        수정 완료
      </Modal>
      <Modal
        isYesNo={true}
        isOpen={editModal}
        onClose={() => setEditModal(false)}
        onYesClick={() => PutHandler()}
      >
        수정 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onYesClick={() => {
          if (deleteItem) {
            deleteHandler(deleteItem);
            setDeleteItem(null); // 선택된 아이템의 id 초기화
          }
        }}
      >
        삭제 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        isOpen={cancelModal}
        onClose={() => {
          setCancelModal(false);
        }}
        onYesClick={() => {
          onEditState(false);
          onRefetch(true);
        }}
      >
        취소 하시겠습니까?
      </Modal>
    </>
  );
};

export default ServiceUpdate;
