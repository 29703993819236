import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { FooterListGetAPI } from "../api/FooterApi";
import FooterAddress from '../components/Footer/FooterAddress';

const FooterPage = () => {
  const [addressList, setAddressList] = useState([]);
  const [refecth, setRefetch] = useState(true);

  useEffect(() => {
    if (refecth == true)
    FooterListGetAPI()
      .then((res) => setAddressList(res.data.dataList))
      .catch((error) => console.log(error));
    setRefetch(false);
  }, [refecth]);

  const Addresses = addressList.map((address, idx) => {
    return (
      <FooterAddress
        address={address}
        key={"footeraddress" + address.id}
        onRefetch={() => setRefetch(true)}
      />
    );
  });

  return (
    <FooterPageWrapper>
      <h1>Footer</h1>
      <FooterInputWrapper>
        <h3>회사 주소</h3>
        {Addresses}
      </FooterInputWrapper>
    </FooterPageWrapper>
  );
};

export default FooterPage;

const FooterPageWrapper = styled.div`
  margin-left: 168px;
  .mainhr {
    width: 30px;

    margin-left: 0;
  }
  .bgcontents {
    margin-left: 50px;
  }
`;

const FooterInputWrapper = styled.div`
  margin-top: 30px;
  margin-left: 50px;

  h3 {
    margin-bottom: 20px;
  }

  .inputwrapper {
    display: flex;
    align-items: center;

    > input {
      display: flex;
      align-items: center;

      width: 716px;
      height: 42px;

      color: #ffffff;

      border: 1px solid #ffffff;
      background-color: transparent;

      padding: 0 15px;

      margin-right: 10px;

      &:focus {
        outline: none;
      }
    }

    > div {
      display: flex;
      align-items: center;

      width: 716px;
      height: 42px;

      border: 1px solid #ffffff;
      background-color: transparent;

      padding: 0 15px;

      margin-right: 10px;
    }

    img {
      width: 30px;
      height: 30px;

      margin-right: 10px;
    }

    svg {
      width: 30px;
      height: 30px;

      margin-right: 10px;
    }
  }
  .id-display {
    &:hover {
      cursor: initial;
    }
  }
`;
