import { atom } from 'recoil';

const AuthState = atom({
  key: "authState",
  default: "",
  effects: [
    ({ setSelf, onSet }) => {
      const savedData = sessionStorage.getItem("jwt");
      // setSelf: atom 값을 설정 혹은 재설정
      if (savedData) setSelf(JSON.parse(savedData));

      // atom이 변화가 감지될 때 작동, Storage에 데이터 저장
      // setSelf에 의해서는 작동하지 않음
      onSet((newValue, _, isReset) => {
        isReset
          ? sessionStorage.removeItem("jwt")
          : sessionStorage.setItem("jwt", JSON.stringify(newValue));
      });
    }
  ]
})

export default AuthState