import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ProjcetRequestDeleteAPI,
  ProjcetRequestGetAPI,
} from "../../api/ConnectApi";
import axios from "axios";
import { styled } from "styled-components";
import { DeleteOutlined, FileOutlined } from "@ant-design/icons";
import Modal from "../Modal/Modal";

const CustomerDetail = ({ setCompleteModal, setErrorModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState({
    clientName: "",
    contact: "",
    contractPoint: "",
    email: "",
    fileList: [
      {
        fileName: "",
        fileType: "",
        fileUrl: "",
        id: 0,
      },
    ],
    id: 0,
    managerNameAndPosition: "",
    projectBudget: "",
    projectDetail: "",
    projectName: "",
    projectPeriod: "",
    projectPreparationStatus: "",
    projectRequestType: "",
    siteName: "",
    startingPoint: "",
  });

  useEffect(() => {
    const requestId = location.pathname.split("/")[3];
    ProjcetRequestGetAPI(requestId)
      .then((response) => setRequestData(response.data.data))
      .catch((error) => console.log(error));
  }, []);

  const downloadFileHandler = async (filename, url) => {
    try {
      const response = await axios.get(url, {
        responseType: "blob", // 이걸 사용하여 바이너리 형태의 데이터로 응답을 받습니다.
      });

      const a = document.createElement("a");
      a.href = URL.createObjectURL(new Blob([response.data]));
      a.download = filename;
      a.click();
      URL.revokeObjectURL(a.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const Downloads = requestData.fileList.map((fileData, idx) => {
    return (
      <div
        className='file-div'
        onClick={() => {
          setDownloadIdx(idx);
          setDownloadModal(true);
        }}
        key={fileData.fileName}
      >
        {fileData.fileName}
        <FileOutlined />
      </div>
    );
  });
  // 모달
  const [downloadModal, setDownloadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [downloadIdx, setDownloadIdx] = useState(0);

  const deleteHandler = () => {
    const requestId = location.pathname.split("/")[3];
    ProjcetRequestDeleteAPI(requestId)
      .then((res) => {
        navigate("/connect/project");
        setCompleteModal(true);
      })
      .catch((error) => setErrorModal(true));
  };

  const downloadHandler = () => {
    downloadFileHandler(
      requestData.fileList[downloadIdx].fileName,
      requestData.fileList[downloadIdx].fileUrl
    )
      .then((res) => setCompleteModal(true))
      .catch((error) => setErrorModal(true));
  };

  return (
    <CustomerDetailWrapper>
      <h4>
        프로젝트 의뢰 상세
        <DeleteOutlined onClick={() => setDeleteModal(true)} />
      </h4>
      <hr />
      <div className='grid-div'>
        <div className='first-row'>
          <div className='column'>
            <div>
              <p>프로젝트명</p>
            </div>
            <div className='info-div'>{requestData.projectName}</div>
          </div>
        </div>
        <div className='second-row'>
          <div className='column'>
            <div>
              <p>프로젝트 분야</p>
            </div>

            <div className='info-div'>{requestData.projectRequestType}</div>
          </div>
          <div className='column'>
            <div>
              <p>프로젝트 기간</p>
            </div>

            <div className='info-div'>{requestData.projectPeriod}</div>
          </div>
          <div className='column'>
            <div>
              <p>프로젝트 준비 현황</p>
            </div>

            <div className='info-div'>
              {requestData.projectPreparationStatus}
            </div>
          </div>
          <div className='column'>
            <div>
              <p>프로젝트 예산</p>
            </div>

            <div className='info-div'>{requestData.projectBudget}</div>
          </div>
          <div className='column'>
            <div>
              <p>기관 또는 기업명</p>
            </div>
            <div className='info-div'>{requestData.clientName}</div>
          </div>
          <div className='column'>
            <div>
              <p>연락처</p>
            </div>

            <div className='info-div'>{requestData.contact}</div>
          </div>
          <div className='column'>
            <div>
              <p>계약 시점</p>
            </div>

            <div className='info-div'>{requestData.contractPoint}</div>
          </div>
          <div className='column'>
            <div>
              <p>이메일</p>
            </div>

            <div className='info-div'>{requestData.email}</div>
          </div>
          <div className='column'>
            <div>
              <p>담당자명/직책</p>
            </div>

            <div className='info-div'>{requestData.managerNameAndPosition}</div>
          </div>

          <div className='column'>
            <div>
              <p>사이트 주소</p>
            </div>

            <div className='info-div'>{requestData.siteName}</div>
          </div>
          <div className='column'>
            <div>
              <p>착수시점</p>
            </div>

            <div className='info-div'>{requestData.startingPoint}</div>
          </div>
        </div>
        <div className='first-row'>
          <div className='column'>
            <div>
              <p>프로젝트 상세내용</p>
            </div>

            <div className='info-div'>{requestData.projectDetail}</div>
          </div>
        </div>
      </div>

      <p>참고 파일</p>
      {Downloads}
      <Modal
        isYesNo={true}
        onClose={() => setDownloadModal(false)}
        isOpen={downloadModal}
        onYesClick={downloadHandler}
      >
        다운로드 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        onYesClick={deleteHandler}
      >
        삭제 하시겠습니까?
      </Modal>
    </CustomerDetailWrapper>
  );
};

export default CustomerDetail;

const CustomerDetailWrapper = styled.div`
  width: 850px;

  margin-bottom: 30px;
  svg {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }
  p {
    display: block;
    width: 170px;
    font-size: 18px;
    font-weight: bold;
  }

  .info-div {
    width: 100%;
    max-width: 716px;
    height: auto;
    min-height: 30px;
    border: 1px solid #ffffff;
    padding: 5px;
  }

  .first-row {
    display: grid;
    grid-template-columns: repeat(1, auto);
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .second-row {
    display: grid;
    grid-template-columns: repeat(2, 420px);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }

  .column {
    display: flex;
  }

  .file-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 850px;
    height: auto;
    min-height: 30px;
    border: 1px solid #ffffff;
    padding: 5px;
    margin-top: 10px;

    &:hover {
      cursor: pointer;
    }
  }
`;
