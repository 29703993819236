import { authApi } from "./api";

const ServiceGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: "api/admin/service",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ServicePostAPI = async (data) => {
  try {
    const response = await authApi({
      method: "post",
      url: "api/admin/service",
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ServiceDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `api/admin/service/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ServicePutAPI = async (data) => {
  const putData = {
    content: data.content,
    sequence: data.sequence,
    serviceType: data.serviceType,
    serviceTypeId: data.serviceTypeId,
  };

  try {
    const response = await authApi({
      method: "put",
      url: `api/admin/service/${data.id}`,
      data: putData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ServiceTagGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: "api/admin/service/type",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ServiceTagPostAPI = async (data) => {
  try {
    const response = await authApi({
      method: "post",
      url: "api/admin/service/type",
      data: { serviceTypeName: data.content },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ServiceTagPutAPI = async (id, data) => {
  try {
    const response = await authApi({
      method: "put",
      url: `api/admin/service/type/${id}`,
      data: {
        serviceTypeName: data,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ServiceTagDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `api/admin/service/type/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjectProcessGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: "api/admin/project-process",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjectProcessPostAPI = async (data) => {
  try {
    const response = await authApi({
      method: "post",
      url: "api/admin/project-process",
      data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjectProcessPutAPI = async (data) => {
  try {
    const response = await authApi({
      method: "put",
      url: `api/admin/project-process/${data.id}`,
      data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjectProcessDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `api/admin/project-process/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjectTypeGetAPI = async (id) => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/project-process/type`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjectTypePostAPI = async (data) => {
  try {
    const response = await authApi({
      method: "post",
      url: `/api/admin/project-process/type`,
      data: { projectProcessTypeName: data.content },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjectTypePutAPI = async (id, categoryName) => {
  try {
    const response = await authApi({
      method: "put",
      url: `/api/admin/project-process/type/${id}`,
      data: {
        projectProcessTypeName: categoryName,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ProjectTypeDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `/api/admin/project-process/type/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PartnersGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: `api/admin/partner`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PartnersSequenceListGetAPI = async () => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/partner/sequence`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PartnersSequenceGetAPI = async (id) => {
  try {
    const response = await authApi({
      method: "get",
      url: `/api/admin/partner/sequence/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PartnersPostAPI = async (data) => {
  const formData = new FormData();

  formData.append("partnerName", data.partnerName);
  formData.append("sequence", data.sequence);
  formData.append("file", data.file);

  try {
    const response = await authApi({
      method: "post",
      url: `api/admin/partner`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PartnersPutAPI = async (id, data) => {
  const formData = new FormData();

  formData.append("partnerName", data.partnerName);
  formData.append("sequence", data.sequence);
  if (data.file) {
    formData.append("file", data.file);
  } else {
    let emptyBlob = new Blob([], { type: "application/octet-stream" });
    formData.append("file", emptyBlob, "filename.txt");
  }

  try {
    const response = await authApi({
      method: "put",
      url: `api/admin/partner/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const PartnersDeleteAPI = async (id) => {
  try {
    const response = await authApi({
      method: "delete",
      url: `api/admin/partner/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  ServiceGetAPI,
  ServicePostAPI,
  ServiceDeleteAPI,
  ServicePutAPI,
  ServiceTagGetAPI,
  ServiceTagPostAPI,
  ServiceTagPutAPI,
  ServiceTagDeleteAPI,
  ProjectProcessGetAPI,
  ProjectProcessPostAPI,
  ProjectProcessPutAPI,
  ProjectProcessDeleteAPI,
  ProjectTypeGetAPI,
  ProjectTypePostAPI,
  ProjectTypePutAPI,
  ProjectTypeDeleteAPI,
  PartnersGetAPI,
  PartnersPostAPI,
  PartnersSequenceGetAPI,
  PartnersSequenceListGetAPI,
  PartnersDeleteAPI,
  PartnersPutAPI,
};
