import React, { useState } from "react";
import ReactDOM from "react-dom";
import { styled } from "styled-components";

const Modal = ({ isOpen, children, onClose, onYesClick, isYesNo }) => {
  // 모달 컴포넌트가 아직 열리지 않았다면, 아무 것도 렌더링하지 않습니다.
  if (!isOpen) {
    return null;
  }

  const yesHandler = () => {
    onYesClick();
    onClose();
  };

  return ReactDOM.createPortal(
    <>
      <Backdrop onClick={onClose} />
      <ModalContent>
        <div className='contentWrapper'>{children}</div>
        <div className='buttonWrapper'>
          {isYesNo ? (
            <>
              <button onClick={yesHandler}>예</button>
              <button onClick={onClose}>아니요</button>
            </>
          ) : (
            <>
              <button className='closebutton' onClick={onClose}>닫기</button>
            </>
          )}
        </div>
      </ModalContent>
    </>,
    document.body // 렌더링할 DOM을 선택합니다. 여기서는 body를 선택했습니다.
  );
};

export default Modal;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.9);

  z-index: 998;
`;

const ModalContent = styled.div`
  position: fixed;

  width: 30%;
  height: 40%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #000000;
  border: 1px solid #ffffff;

  z-index: 999;

  .contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;

    button {
      background-color: transparent;
      color: white;
      border: #ffffff 1px solid;
      width: 50%;
      height: 100%;

      &:hover {
        background-color: #707070;
      }
    }
    .closebutton {
      width: 100%;
    }
  }
`;
