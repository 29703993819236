import { useEffect, useState } from "react";
import { ProjcetAreaDeleteAPI } from "../../api/WorkApi";
import { styled } from "styled-components";
import Modal from "../Modal/Modal";
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const WorkArea = ({ areaList, onRefetch }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const deleteHandler = () => {
    ProjcetAreaDeleteAPI(deleteId)
      .then(() => {
        setCompleteModal(true);
      })
      .catch(() => {
        setErrorModal(true);
      })
      .finally(() => {
        onRefetch();
      });
  };

  const deleteModalHandler = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const Areas = areaList.map((area) => {
    return (
      <GridDiv className={area.gridAreaType} key={"projectarea-" + area.projectAreaId}>
        <div className='area-header'>
          <Link to={`detail/${area.projectAreaId}`}>
            <img src='/assets/Edit.svg' alt='' />
          </Link>
          <DeleteOutlined
            onClick={() => deleteModalHandler(area.projectAreaId)}
          />
        </div>
        {area.projectInfoDto ? (
          <>
            <div className='blur-area' />
            <img src={area.projectInfoDto.mainFile.fileUrl} alt='' />
            <div className='text-area'>
              <div className='text-area-title'>
                <span>{area.projectInfoDto.projectName}</span>
              </div>
              <hr />
              <div className='text-area-context'>
                <span>Project End Date</span>
                <span>{area.projectInfoDto.endDate}</span>
              </div>
              <div className='text-area-context'>
                <span>Client</span>
                <span>{area.projectInfoDto.clientName}</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='first-edit'>
              <img className='edit-img' src='/assets/Edit.svg' alt='' />
            </div>
          </>
        )}
      </GridDiv>
    );
  });

  return (
    <>
      <GridContainer>{Areas}</GridContainer>
      <Modal
        isYesNo={true}
        onClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        onYesClick={() => deleteHandler()}
      >
        삭제 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </>
  );
};

export default WorkArea;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-top: 34px;
  margin-right: 52px;
`;

const GridDiv = styled.div`
  position: relative;
  height: 815px;

  overflow: hidden;

  &.full {
    grid-column: span 2;
    .text-area {
      width: 682px;
    }
    @media (max-width: 1600px) {
      .text-area {
        width: 495px;
      }
    }
  }

  .text-area {
    position: absolute;
    opacity: 0;
    width: 516px;

    right: 34px;
    bottom: 64px;
    transition: 0.3s ease-out;
    .text-area-title {
      text-align: left;
      font: normal normal bold 32px/56px Poppins;
      letter-spacing: -0.64px;
      color: #ffffff;
      opacity: 1;
    }
    hr {
      margin: 0;
      width: 0;
      transition: 0.3s ease-out;
    }
    .text-area-context {
      display: flex;
      justify-content: space-between;

      text-align: left;
      font: normal normal normal 24px/56px Poppins;
      letter-spacing: -0.48px;
      color: #ffffff;
      opacity: 1;

      border-bottom: #585858 1px solid;
    }

    @media (max-width: 1600px) {
      width: 327px;
      right: 36px;
      bottom: 44px;
      .text-area-title {
        text-align: left;
        font: normal normal bold 20px/56px Poppins;
        letter-spacing: -0.4px;
        color: #ffffff;
        opacity: 1;
      }
      .text-area-context {
        text-align: right;
        font: normal normal normal 14px/56px Poppins;
        letter-spacing: -0.28px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    z-index: -1;
    transition: 0.3s ease-out;
  }
  .blur-area {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: #000000;
    opacity: 0;
    transition: 0.3s ease-out;
  }
  @media (hover) {
    &:hover {
      .blur-area {
        opacity: 0.7;
      }
      img {
        transform: scale(1.1);
      }
      .text-area {
        opacity: 1;
        hr {
          width: 100%;
        }
      }
      .area-header {
        display: flex;
      }
    }
  }

  .area-header {
    position: absolute;
    display: none;
    justify-content: space-between;

    padding: 10px;
    width: 100%;

    z-index: 10;

    img {
      position: relative;
      width: 30px;
      height: 30px;
    }

    svg {
      position: relative;
      width: 30px;
      height: 30px;
    }
  }

  .first-edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #585858;
  }
  .edit-img {
    position: inherit;
    width: 100px;
    height: 100px;
    z-index: 0;
    &:hover {
      cursor: initial;
    }
  }
`;
