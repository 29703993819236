import { useEffect, useState } from "react";
import {
  CompanyProfileDeleteAPI,
  CompanyProfileListGetAPI,
  CompanyProfilePostAPI,
} from "../../api/ConnectApi";
import { DeleteOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import Modal from "../Modal/Modal";

const CompanyProfile = () => {
  const [profileList, setProfileList] = useState([]);
  const [refetch, setRefetch] = useState(true);
  const [addState, setAddState] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [newProfile, setNewProfile] = useState({
    status: false,
    title: "",
    file: null,
  });

  useEffect(() => {
    if (refetch == true)
      CompanyProfileListGetAPI()
        .then((res) => setProfileList(res.data.dataList))
        .catch((error) => console.log(error))
        .finally(() => {
          setRefetch(false);
        });
  }, [refetch]);

  const addFileHandler = (event) => {
    // 입력에서 선택한 파일을 가져옵니다.
    const file = event.target.files[0];
    if (file) {
      setNewProfile((prev) => {
        return { ...prev, title: file.name, file: file };
      });
    }
    event.target.value = null;
  };

  // 모달 상태
  const [deleteModal, setDeleteModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const addHandler = () => {
    CompanyProfilePostAPI(newProfile)
      .then(() => {
        setCompleteModal(true);
        setAddState(false);
      })
      .catch(() => {
        setErrorModal(true);
      })
      .finally(() => {
        setRefetch(true);
      });
  };

  const deleteHandler = () => {
    CompanyProfileDeleteAPI(deleteId)
      .then(() => {
        setCompleteModal(true);
      })
      .catch(() => {
        setErrorModal(true);
      })
      .finally(() => {
        setRefetch(true);
      });
  };

  const Profiles = profileList.map((p, idx) => {
    return (
      <div className='profile-wrapper-div' key={p.title + p.id}>
        <div className='profile-div'>
          {p.title} - {p.fileName}
        </div>
        <DeleteOutlined
          onClick={() => {
            setDeleteId(p.id);
            setDeleteModal(true);
          }}
        />
      </div>
    );
  });

  return (
    <>
      <CompanyProfileWrapper>
        <div className='profile-header'>
          <h4>회사소개서</h4>
          <img src='/assets/Add.svg' alt='' onClick={() => setAddState(true)} />
        </div>
        <div className='profile-contents'>
          {Profiles}
          {addState && (
            <>
              <div className='profile-wrapper-div'>
                <label htmlFor='profile-file' className='profile-div'>
                  {newProfile.file ? (
                    <span>{newProfile.title} </span>
                  ) : (
                    <span>파일을 입력해주세요.</span>
                  )}
                  <img src='/assets/Save.svg' alt='' />
                </label>
                <input
                  type='file'
                  id='profile-file'
                  onChange={addFileHandler}
                />
              </div>
              <div className='buttonwrapper'>
                <img
                  src='/assets/check.svg'
                  alt=''
                  onClick={() => setAcceptModal(true)}
                />
                <img
                  src='/assets/close.svg'
                  alt=''
                  onClick={() => setCancelModal(true)}
                />
              </div>
            </>
          )}
        </div>
      </CompanyProfileWrapper>
      <Modal
        isYesNo={true}
        onClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        onYesClick={() => deleteHandler()}
      >
        삭제 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setCancelModal(false)}
        isOpen={cancelModal}
        onYesClick={() => setAddState(false)}
      >
        취소 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setAcceptModal(false)}
        isOpen={acceptModal}
        onYesClick={addHandler}
      >
        추가 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </>
  );
};

export default CompanyProfile;

const CompanyProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .profile-header {
    display: flex;
    flex-direction: row;

    align-items: center;

    h4 {
      margin-right: 10px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .profile-contents {
    margin-left: 50px;
  }

  .profile-wrapper-div {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  input {
    display: none;
  }

  .profile-div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 716px;
    height: 42px;

    border: 1px solid #ffffff;
    background-color: transparent;

    padding: 0 15px;

    margin-right: 10px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 0px;
    }
  }

  .buttonwrapper {
    display: flex;
    justify-content: end;
    width: 756px;
    height: 42px;
    margin: 0;
    margin-top: 10px;
  }

  img {
    width: 30px;
    height: 30px;

    margin-right: 10px;
  }

  svg {
    width: 30px;
    height: 30px;

    margin-right: 10px;
  }
`;
