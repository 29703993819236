import { Navigate, Route, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AuthState from "../../store/AuthStore";

const PrivateRoute = ({ children }) => {
  const authState = useRecoilValue(AuthState);

  return authState ? <>{children}</> : <Navigate to='/login' />;
};

export default PrivateRoute;
