import { useEffect, useState } from "react";
import { BackgroundGetAPI, BackgroundSeqListGetAPI } from "../api/MainApi";
import BackgroundList from "../components/Main/BackgroundList";
import { styled } from "styled-components";

const MainPage = () => {
  const [refetchState, setRefetchState] = useState(true);

  const [backgroundList, setBackgroundList] = useState({
    MOBILE: [],
    TABLET: [],
    MONITOR: [],
  });

  useEffect(() => {
    if (refetchState == true) {
      BackgroundGetAPI()
        .then((res) => {
          const data = res.data.dataList;
          const sortedByType = {
            MOBILE: [],
            TABLET: [],
            MONITOR: [],
          };

          // 데이터를 backgroundType에 따라 분류
          data.forEach((item) => {
            if (sortedByType.hasOwnProperty(item.backgroundType)) {
              sortedByType[item.backgroundType].push(item);
            }
          });

          // 각 항목을 sequence로 정렬
          Object.keys(sortedByType).forEach((key) => {
            sortedByType[key].sort((a, b) => a.sequence - b.sequence);
          });

          setBackgroundList(sortedByType);
        })
        .catch((error) => console.log(error));

      setRefetchState(false);
    }
  }, [refetchState]);

  const BackLists = Object.keys(backgroundList).map((key) => {
    return (
      <BackgroundList
        ListName={key}
        List={backgroundList[key]}
        onRefetch={() => setRefetchState(true)}
        key={key + "backgroundlist"}
      />
    );
  });

  return (
    <MainPageWrapper>
      <h1>Main</h1>
      <hr className='mainhr' />
      <h3>Background 관리</h3>
      <div className='bgcontents'>{BackLists}</div>
    </MainPageWrapper>
  );
};

export default MainPage;

const MainPageWrapper = styled.div`
  margin-left: 168px;
  .mainhr {
    width: 30px;

    margin-left: 0;
  }
  .bgcontents {
    margin-left: 50px;
  }
`;
