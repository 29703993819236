import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { ProjectProcessPostAPI, ProjectTypePostAPI, ServicePostAPI } from "../../api/AboutApi";

const ProjectAdd = ({
  processlist,
  processTypeList,
  onRefetch,
  onAddState,
}) => {
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const [postContentType, setPostContentType] = useState("");

  useEffect(() => {
    setPostContentType(processTypeList[0].projectProcessTypeName);
  }, []);

  const [postData, setPostData] = useState({
    content: "",
    sequence:
      processlist[postContentType] && processlist[postContentType].length > 0
        ? processlist[postContentType][processlist[postContentType].length - 1]
            .sequence + 1
        : 1,
  });

  useEffect(() => {
    setPostData((prev) => {
      return {
        ...prev,
        projectProcessTypeId: processTypeList[0].id,
      };
    });
    setPostContentType(processTypeList[0].projectProcessTypeName);
  }, [processlist]);

  useEffect(() => {
    setPostData((prev) => {
      return {
        ...prev,
        sequence:
        processlist[postContentType] && processlist[postContentType].length > 0
            ? processlist[postContentType][processlist[postContentType].length - 1]
                .sequence + 1
            : 1,
      };
    });
  }, [postContentType]);

  const optionHandler = (e) => {
    const [type, id] = e.target.value.split("|");
    setPostContentType(type);
    setPostData((prev) => {
      return {
        ...prev,
        projectProcessType: type,
        projectProcessTypeId: id,
        sequence:
          processlist[type] && processlist[type].length > 0
            ? processlist[type][processlist[type].length - 1].sequence + 1
            : 1,
      };
    });
  };

  const inputHandler = (e) => {
    setPostData((prev) => {
      return { ...prev, content: e.target.value };
    });
  };

  const PostHandler = async () => {
    const data = {
      ...postData,
      projectProcessType: postContentType,
    };

    if (postContentType === "ProcessAdd") {
      ProjectTypePostAPI(data)
        .then((res) => {
          setAcceptModal(false);
          onAddState(false);
        })
        .catch((err) => {
          setAcceptModal(false);
          setErrorModal(true);
          onAddState(false);
          console.log(err);
        })
        .finally(() => onRefetch(true));
      return;
    }

    ProjectProcessPostAPI(data)
      .then(() => {
        setCompleteModal(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorModal(true);
      })
      .finally(() => {
        setPostData({
          content: "",
          sequence:
            processlist[postContentType] &&
            processlist[postContentType].length > 0
              ? processlist[postContentType][
                  processlist[postContentType].length - 1
                ].sequence + 1
              : 1,
        });
        onAddState(false);

        onRefetch();
      });
  };

  const TypeSelecter =
    processTypeList &&
    processTypeList.map((type, idx) => {
      return (
        <option
          key={type.projectProcessTypeName + type.id}
          value={`${type.projectProcessTypeName}|${type.id}`}
        >
          {type.projectProcessTypeName}
        </option>
      );
    });

  return (
    <div className='service-add'>
      <div>
        <h4>Service</h4>
        <select name='' id='' onChange={optionHandler}>
          {TypeSelecter}
          <option value='ProcessAdd'>프로세스 항목 추가</option>
        </select>
      </div>
      <div>
        <h4>Text</h4>
        <input type='text' value={postData.content} onChange={inputHandler} />
        <img
          src='/assets/check.svg'
          alt=''
          onClick={() => setAcceptModal(true)}
        />
        <img
          src='/assets/close.svg'
          alt=''
          onClick={() => setCancelModal(true)}
        />
      </div>
      <Modal
        isYesNo={true}
        onClose={() => setCancelModal(false)}
        isOpen={cancelModal}
        onYesClick={() => onAddState(false)}
      >
        취소 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={true}
        onClose={() => setAcceptModal(false)}
        isOpen={acceptModal}
        onYesClick={PostHandler}
      >
        추가 하시겠습니까?
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setErrorModal(false)}
        isOpen={errorModal}
      >
        에러 발생
      </Modal>
      <Modal
        isYesNo={false}
        onClose={() => setCompleteModal(false)}
        isOpen={completeModal}
      >
        완료
      </Modal>
    </div>
  );
};

export default ProjectAdd;
