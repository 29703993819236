import { Route, Routes } from "react-router-dom";
import Service from "../components/About/Service";
import PrivateRoute from "../components/common/PrivateRoute";
import Project from "../components/About/Project";
import Partners from "../components/About/Partners";
import { styled } from "styled-components";

const AboutPage = () => {
  return (
    <AboutPageWrapper>
      <h1>About</h1>
      <hr className='mainhr' />
      <Routes>
        <Route path='/service' element={<Service />} />
        <Route path='/process' element={<Project />} />
        <Route path='/partners' element={<Partners />} />
      </Routes>
    </AboutPageWrapper>
  );
};

export default AboutPage;

const AboutPageWrapper = styled.div`
  margin-left: 168px;
  .mainhr {
    width: 30px;

    margin-left: 0;
  }
  .bgcontents {
    margin-left: 50px;
  }
`;
