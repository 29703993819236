import axios from "axios";

const BASE_URL = "https://api.admin.boundary.team/";
const jwtToken = window.sessionStorage.getItem("jwtToken");

const api = axios.create({
  baseURL: BASE_URL,
});

const authApi = axios.create({
  baseURL: BASE_URL,
});

authApi.interceptors.response.use(
  response => {
    const { success, code, message } = response.data;
    if (!success && code >= 400) {
      throw new Error(message || `Error with status code: ${code}`);
    }

    return response;
  },
  error => {
    console.error('Error intercepted:', error.response ? error.response.status : 'Unknown error');
    return Promise.reject(error);
  }
);

authApi.interceptors.request.use(
  (config) => {
    const jwtToken = window.sessionStorage.getItem("jwt");
    if (jwtToken) {
      config.headers.Authorization = JSON.parse(jwtToken);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const loginAPI = async (formData) => {
  try {
    const response = await api({
      method: 'post',
      url: 'api/admin/login',
      data: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export {api, authApi, loginAPI, }